import Navbar from "./Navbar";
import "../styles/BornToBeach.css";
import brauunion from "../assets/logos/brauunion.png";
import redbull from "../../public/partner_logos/redBull.png";
import skruf from "../../public/partner_logos/skruf.png";
import AboutImage from "../assets/random/placeholder1.jpg";
import Footer from "./Footer";
import React, { useEffect, useState } from "react";
import btbImage from "../assets/logos/btb.png";
import SingleNavbar from "./SingleNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

export interface ScrollToTopButtonProps {}

export default function ScrollToTopButton({}: ScrollToTopButtonProps) {
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.scrollY > 300
        ? setShowScrollButton(true)
        : setShowScrollButton(false);
    };
    window.addEventListener("scroll", handleScrollButtonVisibility);
    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisibility);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={"scrollToTop"}>
      {showScrollButton && (
        <button className={"scrollToTopButton"} onClick={handleScrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} color={"#004C6F"} />
        </button>
      )}
    </div>
  );
}
