import "../styles/Navbar.css";
import ReactDatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import { EventData, PartnerData } from "./Routes";
import { v4 as uuidv4 } from "uuid";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { SelectItem } from "primereact/selectitem";
import React, { ChangeEvent, useMemo, useState } from "react";
import { EventState } from "./Dashboard";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export enum EventType {
  beach = "beach",
  event = "event",
}

export interface EventFormItemProps {
  image?: File;
  eventId?: string;
  imageName: string;
  request?: (values: FormikValues, id?: string, image?: File) => void;
  buttonName?: string;
  setEvents?: (events: EventData[]) => void;
  partners?: PartnerData[];
  eventState: EventState;
}

export default function EventFormImageOnly({
  eventId,
  request,
  buttonName,
  setEvents,
  partners,
  imageName,
  eventState,
  image,
}: EventFormItemProps) {
  const SignupSchema = Yup.object().shape({});

  const [curImage, setImage] = useState<File | undefined>(image || undefined);
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
    }
  };

  return (
    <Formik
      validationSchema={SignupSchema}
      initialValues={{
        eventId: eventId ? eventId : uuidv4(),
        image: image,
      }}
      onSubmit={(values, { setSubmitting }) => {
        request && request(values, eventId, curImage);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setValues,
        isValid,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form className="form" onSubmit={handleSubmit}>
          <div className={"formRow"}>
            <div className="input-group">
              <label htmlFor="image">{imageName}</label>
              <input
                type="file"
                name="image"
                onChange={(e) => {
                  handleImageChange(e);
                  if (e.target.files !== null && e.target.files[0]) {
                    setFieldValue("image", e.target.files[0]);
                  }
                }}
              />
            </div>
          </div>
          <div className={"formRow"}>
            <button className="primary">{buttonName}</button>
          </div>
        </form>
      )}
    </Formik>
  );
}
