import { Link } from "react-router-dom";
import "../styles/Navbar.css";
import React, { useState } from "react";
import Facebook from "../assets/icons/facebook-f-brands.svg";
import Instagram from "../assets/icons/instagram-brands.svg";
import InstagramXMark from "../assets/icons/xmark-solid-ig.svg";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface SingleNavbarProps {
  imageUrl: string;
  selectedTab:
    | "club"
    | "contact"
    | "events"
    | "about"
    | "eventName"
    | "none"
    | "agentur";
  eventName?: string;
  extended: boolean;
  onExtendedChange: (val: boolean) => void;
  directInstagramLink?: boolean;
}

export default function SingleNavbar({
  imageUrl,
  selectedTab,
  extended,
  onExtendedChange,
  eventName,
  directInstagramLink = false,
}: SingleNavbarProps) {
  const [displayInstagram, setDisplayInstagram] = useState(false);
  return (
    <div>
      <nav className={extended ? "navigation expanded" : "navigation"}>
        <div
          className={
            extended ? "navigationContent expanded" : "navigationContent"
          }
        >
          <div className={"navHeaderRow"}>
            <div className={"logoImageWrapper"}>
              <Link className={"link instagramLink"} to={"/"}>
                <img
                  src={imageUrl}
                  className={`logoImage ${
                    selectedTab === "contact" ||
                    selectedTab === "none" ||
                    selectedTab === "about"
                      ? "btb"
                      : ""
                  }`}
                />
              </Link>
            </div>
            <button
              className="hamburger"
              onClick={() => {
                onExtendedChange(!extended);
              }}
            >
              <FontAwesomeIcon icon={extended ? faX : faBars} color={"white"} />
            </button>
            <div className={"selectedTabWrapper"}>
              {selectedTab === "club" && <p className={"link"}>Beach Club</p>}
              {selectedTab === "events" && <p className={"link"}>Events</p>}
              {selectedTab === "about" && <p className={"link"}>Über uns</p>}
              {selectedTab === "contact" && <p className={"link"}>Kontakt</p>}
              {selectedTab === "eventName" && (
                <p className={"link"}>{eventName}</p>
              )}
            </div>
            <div className={"navigation-menu"}>
              {!extended && (
                <div className={"linkWrapper"} data-staticsize={"club"}>
                  {displayInstagram && (
                    <Link
                      className={"link instagramLink"}
                      to={"https://www.instagram.com/borntobeach.club/"}
                      target={"_blank"}
                    >
                      Beach Club
                    </Link>
                  )}
                  {!displayInstagram && (
                    <Link
                      className={`link ${
                        selectedTab === "club" ? "selected" : ""
                      }`}
                      to={"/beachClub"}
                    >
                      Beach Club
                    </Link>
                  )}
                </div>
              )}
              {!extended && (
                <div className={"linkWrapper"} data-staticsize={"event"}>
                  {displayInstagram && (
                    <Link
                      className={"link instagramLink"}
                      to={"https://www.instagram.com/borntobeach.events/"}
                      target={"_blank"}
                    >
                      Events
                    </Link>
                  )}
                  {!displayInstagram && (
                    <Link
                      className={`link ${
                        selectedTab === "events" ? "selected" : ""
                      }`}
                      to={"/events"}
                    >
                      Events
                    </Link>
                  )}
                </div>
              )}
              {!extended && (
                <div className={"linkWrapper"} data-staticsize={"agentur"}>
                  {displayInstagram && (
                    <Link
                      className={"link instagramLink"}
                      to={"https://www.instagram.com/beachgroup.agentur/"}
                      target={"_blank"}
                    >
                      Agentur
                    </Link>
                  )}
                  {!displayInstagram && (
                    <Link
                      className={`link ${
                        selectedTab === "agentur" ? "selected" : ""
                      }`}
                      to={"/agentur"}
                    >
                      Agentur
                    </Link>
                  )}
                </div>
              )}
              {!extended && (
                <div
                  className={`linkWrapper ${
                    displayInstagram ? "transparent" : ""
                  }`}
                  data-staticsize={"about"}
                >
                  <Link
                    className={`link ${
                      selectedTab === "about" ? "selected" : ""
                    }`}
                    to={"/about"}
                  >
                    Über uns
                  </Link>
                </div>
              )}
              {!extended && (
                <div
                  className={`linkWrapper ${
                    displayInstagram ? "transparent" : ""
                  }`}
                  data-staticsize={"contact"}
                >
                  <Link
                    className={`link ${
                      selectedTab === "contact" ? "selected" : ""
                    }`}
                    to={"/contact"}
                  >
                    Kontakt
                  </Link>
                </div>
              )}
            </div>
            <div className={"socialWrapper"}>
              <Link
                className={"socialButton"}
                to="https://www.facebook.com/BornToBeach.Events.BeachClub"
                target="_blank"
              >
                <img className={"socialIcon"} src={Facebook} alt={"Fb"} />
              </Link>
              {directInstagramLink && selectedTab === "club" && (
                <Link
                  className={"socialButton"}
                  to={"https://www.instagram.com/borntobeach.club/"}
                  target={"_blank"}
                >
                  <img
                    className={"socialIcon"}
                    src={displayInstagram ? InstagramXMark : Instagram}
                    alt={"Ig"}
                  />{" "}
                </Link>
              )}
              {directInstagramLink && selectedTab === "events" && (
                <Link
                  className={"socialButton"}
                  to={"https://www.instagram.com/borntobeach.events/"}
                  target={"_blank"}
                >
                  <img
                    className={"socialIcon"}
                    src={displayInstagram ? InstagramXMark : Instagram}
                    alt={"Ig"}
                  />{" "}
                </Link>
              )}
              {!directInstagramLink && (
                <button
                  className={"socialButton"}
                  onClick={() => {
                    setDisplayInstagram(!displayInstagram);
                  }}
                >
                  <img
                    className={"socialIcon"}
                    src={displayInstagram ? InstagramXMark : Instagram}
                    alt={"Ig"}
                  />
                </button>
              )}
            </div>
          </div>
          {extended && (
            <>
              <div className={extended ? "navSpacer" : ""}></div>
              <div
                className={
                  selectedTab === "club"
                    ? "linkWrapperExtended selectedLinkWrapper"
                    : "linkWrapperExtended"
                }
                data-staticsize={"club"}
              >
                {displayInstagram && (
                  <Link
                    className={"link instagramLink"}
                    to={"https://www.instagram.com/borntobeach.club/"}
                    target={"_blank"}
                  >
                    Beach Club
                  </Link>
                )}
                {!displayInstagram && (
                  <>
                    <Link
                      className={`link ${
                        selectedTab === "club" ? "selected" : ""
                      }`}
                      to={"/beachClub"}
                    >
                      Beach Club
                    </Link>
                    <Link
                      className={"link instagramLink"}
                      to={"https://www.instagram.com/borntobeach.club/"}
                      target={"_blank"}
                    >
                      <img
                        className={"socialIcon"}
                        src={Instagram}
                        alt={"Fb"}
                      />
                    </Link>
                    <Link
                      className={"socialButton"}
                      to="https://www.facebook.com/BornToBeach.Events.BeachClub"
                      target="_blank"
                    >
                      <img className={"socialIcon"} src={Facebook} alt={"Fb"} />
                    </Link>
                  </>
                )}
              </div>
              <div
                className={
                  selectedTab === "events"
                    ? "linkWrapperExtended selectedLinkWrapper"
                    : "linkWrapperExtended" +
                      ` ${displayInstagram ? "transparent" : ""}`
                }
                data-staticsize={"events"}
              >
                <Link
                  className={`link ${
                    selectedTab === "events" ? "selected" : ""
                  }`}
                  to={"/events"}
                >
                  Events
                </Link>
                <Link
                  className={"link instagramLink"}
                  to={"https://www.instagram.com/borntobeach.events/"}
                  target={"_blank"}
                >
                  <img
                    className={"socialIcon"}
                    src={Instagram}
                    alt={"Instagram"}
                  />
                </Link>
              </div>
              <div
                className={
                  selectedTab === "agentur"
                    ? "linkWrapperExtended selectedLinkWrapper"
                    : "linkWrapperExtended" +
                      ` ${displayInstagram ? "transparent" : ""}`
                }
                data-staticsize={"agentur"}
              >
                <Link
                  className={`link ${
                    selectedTab === "agentur" ? "selected" : ""
                  }`}
                  to={"/agentur"}
                >
                  Agentur
                </Link>
              </div>
              <div
                className={
                  selectedTab === "about"
                    ? "linkWrapperExtended selectedLinkWrapper"
                    : "linkWrapperExtended" +
                      ` ${displayInstagram ? "transparent" : ""}`
                }
                data-staticsize={"about"}
              >
                <Link
                  className={`link ${
                    selectedTab === "about" ? "selected" : ""
                  }`}
                  to={"/about"}
                >
                  Über uns
                </Link>
              </div>
              <div
                className={
                  selectedTab === "contact"
                    ? "linkWrapperExtended selectedLinkWrapper"
                    : "linkWrapperExtended" +
                      ` ${displayInstagram ? "transparent" : ""}`
                }
                data-staticsize={"contact"}
              >
                <Link
                  className={`link ${
                    selectedTab === "contact" ? "selected" : ""
                  }`}
                  to={"/contact"}
                >
                  Kontakt
                </Link>
              </div>
            </>
          )}
        </div>
      </nav>
      <div className={extended ? "spacerExtended" : "spacer"}></div>
    </div>
  );
}
