import { Link } from "react-router-dom";
import "../styles/Navbar.css";
import React, { useState } from "react";
import Facebook from "../assets/icons/facebook-f-brands.svg";
import Instagram from "../assets/icons/instagram-brands.svg";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface AgenturNavbarProps {
  imageUrl: string;
  selectedTab: "agentur" | "contact";
  eventName?: string;
  extended: boolean;
  onExtendedChange: (val: boolean) => void;
  directInstagramLink?: boolean;
}

export default function AgenturNavbar({
  imageUrl,
  selectedTab,
  extended,
  onExtendedChange,
  eventName,
  directInstagramLink = false,
}: AgenturNavbarProps) {
  const [displayInstagram, setDisplayInstagram] = useState(false);
  return (
    <div>
      <nav className={extended ? "navigation expanded" : "navigation"}>
        <div
          className={
            extended ? "navigationContent expanded" : "navigationContent"
          }
        >
          <div className={"navHeaderRow"}>
            <div className={"logoImageWrapper"}>
              <Link className={"link instagramLink"} to={"/"}>
                <img src={imageUrl} className={`logoImage agentur`} />
              </Link>
            </div>
            <button
              className="hamburger"
              onClick={() => {
                onExtendedChange(!extended);
              }}
            >
              <FontAwesomeIcon icon={extended ? faX : faBars} color={"white"} />
            </button>
            <div className={"selectedTabWrapper"}>
              {selectedTab === "agentur" && <p className={"link"}>Agentur</p>}
              {selectedTab === "contact" && <p className={"link"}>Kontakt</p>}
            </div>
            <div className={"navigation-menu"}>
              {!extended && (
                <div className={"linkWrapper"} data-staticsize={"agentur"}>
                  {!displayInstagram && (
                    <Link
                      className={`link ${
                        selectedTab === "agentur" ? "selected" : ""
                      }`}
                      to={"/agentur"}
                    >
                      Agentur
                    </Link>
                  )}
                </div>
              )}
              {!extended && (
                <div
                  className={`linkWrapper ${
                    displayInstagram ? "transparent" : ""
                  }`}
                  data-staticsize={"contact"}
                >
                  <Link
                    className={`link ${
                      selectedTab === "contact" ? "selected" : ""
                    }`}
                    to={"/agentur/contact"}
                  >
                    Kontakt
                  </Link>
                </div>
              )}
              {!extended && (
                <div
                  className={`linkWrapper ${
                    displayInstagram ? "transparent" : ""
                  }`}
                  data-staticsize={"homepage"}
                >
                  <Link className={`link`} to={"/"}>
                    Born To Beach
                  </Link>
                </div>
              )}
            </div>
            <div className={"socialWrapper"}>
              <Link
                className={"socialButton"}
                to="https://www.facebook.com/beachgroup.agentur/"
                target="_blank"
              >
                <img className={"socialIcon"} src={Facebook} alt={"Fb"} />
              </Link>
              <Link
                className={"socialButton"}
                to="https://www.instagram.com/beachgroup.agentur/"
                target="_blank"
              >
                <img className={"socialIcon"} src={Instagram} alt={"IG"} />
              </Link>
            </div>
          </div>
          {extended && (
            <>
              <div className={extended ? "navSpacer" : ""}></div>
              <div
                className={
                  selectedTab === "agentur"
                    ? "linkWrapperExtended selectedLinkWrapper"
                    : "linkWrapperExtended" +
                      ` ${displayInstagram ? "transparent" : ""}`
                }
                data-staticsize={"agentur"}
              >
                <Link
                  className={`link ${
                    selectedTab === "agentur" ? "selected" : ""
                  }`}
                  to={"/agentur"}
                >
                  Agentur
                </Link>
                <Link
                  className={"link instagramLink"}
                  to="https://www.instagram.com/beachgroup.agentur/"
                  target={"_blank"}
                >
                  <img className={"socialIcon"} src={Instagram} alt={"IG"} />
                </Link>
                <Link
                  className={"socialButton"}
                  to="https://www.facebook.com/beachgroup.agentur/"
                  target="_blank"
                >
                  <img className={"socialIcon"} src={Facebook} alt={"Fb"} />
                </Link>
              </div>
              <div
                className={
                  selectedTab === "contact"
                    ? "linkWrapperExtended selectedLinkWrapper"
                    : "linkWrapperExtended" +
                      ` ${displayInstagram ? "transparent" : ""}`
                }
                data-staticsize={"contact"}
              >
                <Link
                  className={`link ${
                    selectedTab === "contact" ? "selected" : ""
                  }`}
                  to={"/agentur/contact"}
                >
                  Kontakt
                </Link>
              </div>
              <div
                className={
                  "linkWrapperExtended" +
                  ` ${displayInstagram ? "transparent" : ""}`
                }
                data-staticsize={"agentur"}
              >
                <Link className={`link`} to={"/"}>
                  Born To Beach
                </Link>
              </div>
            </>
          )}
        </div>
      </nav>
      <div className={extended ? "spacerExtended" : "spacer"}></div>
    </div>
  );
}
