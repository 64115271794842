import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { AuthProvider } from "react-auth-kit";
import { Routes } from "react-router-dom";
import RoutesComponent from "./layout/Routes";
import MetaPixel from "./utils/meta/metaPixel";

function App() {
  return (
    <AuthProvider authName={"_auth"} authType={"cookie"}>
      <MetaPixel />
      <RoutesComponent />
    </AuthProvider>
  );
}

export default App;
