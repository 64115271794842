import {
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import Footer from "./Footer";
import BornToBeach from "./BornToBeach";
import BornToClub from "./BornToClub";
import Events from "./Events";
import About from "./About";
import Contact from "./Contact";
import Impressum from "./Impressum";
import Datenschutz from "./Datenschutz";
import Login from "./Login";
import React, { useEffect, useState } from "react";
import { RequireAuth } from "react-auth-kit";
import Root from "./Root";
import Dashboard from "./Dashboard";
import axios from "axios";
import ScrollToTop from "./ScrollToTop";
import Wartungsarbeiten from "./Wartungsarbeiten";
import BeachClub from "./BeachClub";
import btbImage from "../assets/logos/btb.png";
import btcImage from "../assets/logos/btb-club.png";
import bteImage from "../assets/logos/btb-events.png";
import agenturImage from "../assets/logos/btb_agentur.png";
import EventWrapper from "./EventWrapper";
import Agentur from "./Agentur";
import AgenturContact from "./AgenturContact";
import ScanQr from "./ScanQr";
import SendQRCodes from "./SendQRCodes";
import SendQRCodesWrapper from "./SendQRCodesWrapper";

export interface EventData {
  id: string;
  name: string;
  date: string;
  text: string;
  type: string;
  ticketLink: string;
  bannerImage: any;
  shouldDisplay: number;
  numImages: number;
}

export interface PartnerData {
  name: string;
}

export interface EventPartner {
  eventId: string;
  partnerId: string;
}

export const WEBSITE_URL = "https://hosting190127.a2f57.netcup.net/";

export default function RoutesComponent() {
  const [events, setEvents] = useState<EventData[]>([]);
  const [partners, setPartners] = useState<PartnerData[]>([]);
  const [eventPartner, setEventPartner] = useState<EventPartner[]>([]);
  const [loadFinished, setLoadFinished] = useState(false);

  useEffect(() => {
    axios
      .get(WEBSITE_URL + "events")
      .then((response) => {
        setEvents(response.data);
        setLoadFinished(true);
      })
      .catch((err) => console.log(err));
  }, []);
  const partnersOfEvent = new Map<string, string[]>();
  const wartungsArbeiten = false;
  const landingBeachClub = false;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path={"/"} element={<Root hideFooter={wartungsArbeiten} />}>
          <Route
            path={""}
            element={
              wartungsArbeiten ? (
                <Wartungsarbeiten />
              ) : landingBeachClub ? (
                <BeachClub logo={btcImage} />
              ) : (
                <BornToBeach
                  logo={btbImage}
                  events={events}
                  partnersOfEvent={partnersOfEvent}
                />
              )
            }
          />
          {wartungsArbeiten && (
            <Route
              path={"home"}
              element={
                <BornToBeach
                  logo={btbImage}
                  events={events}
                  partnersOfEvent={partnersOfEvent}
                />
              }
            />
          )}
          <Route
            path={"contact"}
            element={<Contact singleNavbar={true} logo={btbImage} />}
          />
          {!landingBeachClub && (
            <>
              <Route
                path={"beachClub"}
                element={<BeachClub logo={btcImage} />}
              />
              <Route
                path={"agentur"}
                element={<Agentur logo={agenturImage} singleNavbar={true} />}
              />
              <Route
                path={"agentur/contact"}
                element={
                  <AgenturContact logo={agenturImage} singleNavbar={true} />
                }
              />
              <Route
                path={"events"}
                element={
                  <Events
                    events={events}
                    partnersOfEvent={partnersOfEvent}
                    logo={bteImage}
                    loadFinished={loadFinished}
                  />
                }
              />
              <Route path={"about"} element={<About logo={btbImage} />} />
              <Route
                path={"impressum"}
                element={<Impressum logo={btbImage} />}
              />
              <Route
                path={"datenschutz"}
                element={<Datenschutz logo={btbImage} />}
              />
              <Route
                path="events/:id"
                element={
                  <EventWrapper
                    events={events}
                    bteLogo={bteImage}
                    btcLogo={btcImage}
                    btbLogo={btbImage}
                  />
                }
              />
              <Route
                path={"login/dashboard"}
                element={<Login type={"admin"} redirectTo={"/dashboard"} />}
              />
              <Route
                path={"login/send-qr"}
                element={<Login type="send-qr" redirectTo={"/send-qr"} />}
              />
              <Route
                path={"login/scan-qr"}
                element={<Login type="scan-qr" redirectTo={"/scan-qr"} />}
              />
              <Route
                path={"scan-qr"}
                element={
                  <RequireAuth loginPath={"/login/scan-qr"}>
                    <ScanQr logo={btbImage} />
                  </RequireAuth>
                }
              />
              <Route
                path={"send-qr"}
                element={
                  <RequireAuth loginPath={"/login/send-qr"}>
                    <SendQRCodesWrapper logo={btbImage} events={events} />
                  </RequireAuth>
                }
              />
              <Route
                path={"dashboard"}
                element={
                  <RequireAuth loginPath={"/login/dashboard"}>
                    <Dashboard
                      events={events}
                      setEvents={setEvents}
                      partners={partners}
                      partnersOfEvent={partnersOfEvent}
                    />
                  </RequireAuth>
                }
              />
              <Route path={"*"} element={<Navigate to={"/"} />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
