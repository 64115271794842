import Navbar from "./Navbar";
import "../styles/BornToBeach.css";
import brauunion from "../assets/logos/brauunion.png";
import redbull from "../../public/partner_logos/redBull.png";
import skruf from "../../public/partner_logos/skruf.png";
import btbImage from "../assets/logos/btb.png";
import AboutImage from "../assets/random/placeholder1.jpg";
import { EventData, WEBSITE_URL } from "./Routes";
import { BornToBeachProps } from "./BornToBeach";
import SingleNavbar from "./SingleNavbar";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Footer from "./Footer";
import ScrollToTopButton from "./ScrollToTopButton";
import axios from "axios";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import { bool } from "yup";
import QRCode from "qrcode.react";
import { log } from "node:util";
import SendQRCodes from "./SendQRCodes";

export interface SendQRCodesWrapperProps {
  events: EventData[];
  logo: string;
}

export default function SendQRCodesWrapper({
  events,
  logo,
}: SendQRCodesWrapperProps) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<string | undefined>(
    undefined
  );
  return (
    <div className={"background"}>
      <SingleNavbar
        selectedTab={"none"}
        imageUrl={logo}
        extended={isNavExpanded}
        onExtendedChange={(val) => setIsNavExpanded(val)}
      ></SingleNavbar>

      <ScrollToTopButton />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div>SELECT EVENT</div>
        <select
          value={selectedEvent}
          onChange={(event) => setSelectedEvent(event.target.value)}
        >
          <option value={""}></option>
          {events
            .filter(
              (event) =>
                DateTime.fromISO(event.date).endOf("day") > DateTime.now()
            )
            .map((event) => (
              <option value={event.id}>{event.id}</option>
            ))}
        </select>
        {selectedEvent !== undefined && (
          <SendQRCodes
            logo={logo}
            event={events.find((event) => event.id === selectedEvent)}
          />
        )}
      </div>
      <Footer footerTab={"none"} />
    </div>
  );
}
