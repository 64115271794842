import { Link, Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { WartungsarbeitenProps } from "./Wartungsarbeiten";
import MetaPixel from "../utils/meta/metaPixel";

export interface RootProps {
  hideFooter: boolean;
}

export default function Root({ hideFooter }: RootProps) {
  return (
    <div className={"pageWrapper"}>
      <Outlet />
    </div>
  );
}
