import "../styles/BornToBeach.css";
import React from "react";
import { useInView } from "react-intersection-observer";
import { ContactProps } from "./Contact";

export interface PresseTextProps {
  text?: string;
}

export default function PresseText({ text }: PresseTextProps) {
  const { ref, inView } = useInView({
    threshold: 0.1, // Adjust this threshold as needed
    triggerOnce: false, // Only trigger the animation once
  });
  return (
    <div
      ref={ref}
      className={`presseTextWrapper ${inView ? "fade-in" : "hidden"}`}
    >
      <div className={"presseText"}>
        Der Born To Beach Club in Podersdorf ist der perfekte Ort für eine
        Auszeit mit purem Urlaubsfeeling direkt am Neusiedler See. Der Beach
        Club verfügt über eine große Outdoor - Sandfläche sowie einen
        Innenbereich, gemütliche Sitzmöglichkeiten & Sonnenliegen - die das
        entspannte Ambiente des „Gutgehen lassen“ direkt am See ausdrücken.
      </div>
      <div className={"presseText"}>
        Erwähnt werden sollte auch der wohl schönste Sonnenuntergang des
        Burgenlandes hinter dem Leitha Gebirge, welcher sich im Beach Club
        perfekt beobachten lässt.
      </div>
      <div className={"presseText"}>
        Das gastronomische Angebot im Beach Club bietet eine ideale Aus wahl an
        saisonal und regional zubereitenden Gerichten. Bei den frischen und
        leckeren Speisen kommt jeder auf seine Kosten. Ganz neu: Frühstücks -
        Snacks.
      </div>
      <div className={"presseText"}>
        Erfrischende Limonaden & Cocktails, coole Lifestyle Drinks oder abends
        auch gerne ein klassischer Longdr ink oder ein Achterl‘ - eine vielfache
        Auswahl an Getränken runden das Angebot ab. Die Cocktails werden mit
        frischen Früchten und Premium Spirituosen gemixt, definitiv ein
        Highlight der Getränkekarte! Vormittags darf’s gern auch Tee oder Kaffee
        sein.
      </div>
      <div className={"presseText"}>
        Durch die gemütliche Atmosphäre und der endlosen Sorge des
        Servicepersonals nach Leib und Wohl jedes Einzelnen ist der Beach Club
        ein beliebter Treffpunkt für Touristen sowie Einheimische. Über den
        Sommer hinweg werden auch eigene Events organisiert mit Live - Musik,
        SängerInnen oder einem Ibiza - DJ.
      </div>
      <div className={"presseText"}>
        Wer also gerne mal dem Alltag entfliehen möchte ist hier genau richtig
        und bekommt eine ausgewogene kulinarische Verwöhnung mit Urlaubsflair
        direkt am Neusiedler See.
      </div>
    </div>
  );
}
