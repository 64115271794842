import "../styles/Dashboard.css";
import btbImage from "../assets/logos/btb.png";
import React, { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import EventFormItem from "./EventFormItem";
import { EventData, PartnerData, WEBSITE_URL } from "./Routes";
import Footer from "./Footer";
import SingleNavbar from "./SingleNavbar";
import EventFormImageOnly from "./EventFormImageOnly";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { useSignIn, useSignOut } from "react-auth-kit";

export enum EventState {
  NONE,
  ADD,
  EDIT,
  EDITBANNER,
  EDITSIDEIMG,
  ADDFOTOS,
  DELETE,
  ACTIVE,
  DEACTIVATE,
  EDITDATA,
}

export interface DashboardProps {
  events?: EventData[];
  setEvents?: (events: EventData[]) => void;
  partners?: PartnerData[];
  partnersOfEvent: Map<string, string[]>;
}

export interface QrCodeElement {
  event: string;
  id: string;
  used: number;
}

export interface CustomAuthState {
  name: string;
  uid: string;
  rights: string;
}

export default function Dashboard({
  events,
  setEvents,
  partners,
  partnersOfEvent,
}: DashboardProps) {
  const signOut = useSignOut();
  useEffect(() => {
    const authState = JSON.parse(Cookies.get("_auth_state") ?? "");
    const customAuthState: CustomAuthState = {
      name: authState.name,
      uid: authState.uid,
      rights: authState.rights,
    };
    if (customAuthState.rights !== "admin") {
      signOut();
    }
  }, [signOut]);

  const [eventState, setEventState] = useState<EventState>(EventState.NONE);
  const [selectedEvent, setSelectedEvent] = useState<EventData | undefined>(
    undefined
  );
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadNumber, setUploadNumber] = useState(0);

  const handleActivate = useCallback(async (id: string) => {
    try {
      const response = await axios.put(WEBSITE_URL + "activate/" + id, {});
      console.log("PUT request successful", response);
    } catch (error) {
      console.error("Error sending PUT request", error);
    }
  }, []); // Empty dependency array means this callback won't change

  const NUMIMAGESPERUPLOAD = 20;

  const uploadImagePart = useCallback(
    (eventImages: FileList, eventId: string, index: number) => {
      const needsAnotherUpload =
        eventImages.length > (index + 1) * NUMIMAGESPERUPLOAD;
      const eventImagesFormData = new FormData();
      for (
        let i = index * NUMIMAGESPERUPLOAD;
        i < eventImages.length && i < (index + 1) * NUMIMAGESPERUPLOAD;
        i++
      ) {
        let image = eventImages[i];
        eventImagesFormData.append("eventImages", image, eventId);
      }
      axios({
        method: "post",
        url: WEBSITE_URL + "eventImages/" + index * NUMIMAGESPERUPLOAD,
        data: eventImagesFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if (res.status === 200 && needsAnotherUpload) {
            setUploadProgress((index + 1) * NUMIMAGESPERUPLOAD);
            setTimeout(
              () => uploadImagePart(eventImages, eventId, index + 1),
              3000
            );
          }
          if (res.status === 200 && !needsAnotherUpload) {
            setIsSubmitting(false);
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          console.log(err);
        })
        .finally(() => {});
    },
    []
  );
  return (
    <>
      <SingleNavbar
        selectedTab={"none"}
        imageUrl={btbImage}
        extended={isNavExpanded}
        onExtendedChange={(val) => setIsNavExpanded(val)}
      ></SingleNavbar>
      <div className={"background"}>
        {eventState === EventState.NONE && (
          <div className={"dashboardWrapper"}>
            <button
              className={"dashboardItemButton"}
              onClick={() => setEventState(EventState.ADD)}
            >
              Neues Event hinzufügen
            </button>
            <button
              className={"dashboardItemButton"}
              onClick={() => setEventState(EventState.EDIT)}
            >
              Event bearbeiten
            </button>
            <button
              className={"dashboardItemButton"}
              onClick={() => setEventState(EventState.DELETE)}
            >
              Event löschen
            </button>
            <button
              className={"dashboardItemButton"}
              onClick={() => setEventState(EventState.ACTIVE)}
            >
              Event aktivieren
            </button>
            <button
              className={"dashboardItemButton"}
              onClick={() => setEventState(EventState.DEACTIVATE)}
            >
              Event deaktivieren
            </button>
          </div>
        )}
        {eventState === EventState.EDIT && (
          <div className={"dashboardWrapper"}>
            <button
              className={"dashboardItemButton"}
              onClick={() => setEventState(EventState.EDITDATA)}
            >
              Daten bearbeiten
            </button>
            <button
              className={"dashboardItemButton"}
              onClick={() => setEventState(EventState.ADDFOTOS)}
            >
              Fotos hinzufügen
            </button>
            <button
              className={"dashboardItemButton"}
              onClick={() => setEventState(EventState.EDITBANNER)}
            >
              Banner bearbeiten
            </button>
            <button
              className={"dashboardItemButton"}
              onClick={() => setEventState(EventState.EDITSIDEIMG)}
            >
              Side img bearbeiten
            </button>
            <button
              className={"cancelButton"}
              onClick={() => setEventState(EventState.NONE)}
            >
              Cancel
            </button>
          </div>
        )}
        {eventState === EventState.ADD && (
          <div className={"eventAdderWrapper"}>
            <h1>EVENT HIZUFÜGEN</h1>
            <EventFormItem
              submitting={isSubmitting}
              eventState={eventState}
              request={(values, id, eventBanner, eventSideImg) => {
                axios
                  .post(WEBSITE_URL + "dashboard", values)
                  .catch((err) => console.log(err));
                const bannerImage = eventBanner;
                const bannerImageFormData = new FormData();
                const eventSideFormData = new FormData();
                if (bannerImage) {
                  bannerImageFormData.set(
                    "bannerImage",
                    bannerImage,
                    values.eventId
                  );
                  axios({
                    method: "post",
                    url: WEBSITE_URL + "bannerImage",
                    data: bannerImageFormData,
                    headers: { "Content-Type": "multipart/form-data" },
                  })
                    .catch((err) => console.log(err))
                    .finally(() => {
                      window.location.reload();
                    });
                }
                if (eventSideImg) {
                  eventSideFormData.set(
                    "eventSideImg",
                    eventSideImg,
                    values.eventId
                  );
                  axios({
                    method: "post",
                    url: WEBSITE_URL + "eventSideImg",
                    data: eventSideFormData,
                    headers: { "Content-Type": "multipart/form-data" },
                  })
                    .catch((err) => console.log(err))
                    .finally(() => {
                      window.location.reload();
                    });
                }
              }}
              buttonName={"Event hinzufügen"}
              partners={partners}
            />
            {isSubmitting && (
              <FontAwesomeIcon icon={faSpinner} color={"black"} spin={true} />
            )}
            <button
              className={"cancelButton"}
              disabled={isSubmitting}
              onClick={() => setEventState(EventState.NONE)}
            >
              Cancel
            </button>
          </div>
        )}
        {eventState === EventState.DELETE && (
          <div className={"eventAdderWrapper"}>
            <h1>EVENT LÖSCHEN (ACHTUNG!!!!!)</h1>
            {!selectedEvent && (
              <>
                {events?.map((e) => (
                  <button
                    onClick={() => {
                      axios
                        .put(WEBSITE_URL + "delete-event/" + e.id)
                        .catch((err) => console.log(err));
                    }}
                  >
                    {e.name}
                  </button>
                ))}
              </>
            )}
            <button
              className={"cancelButton"}
              disabled={isSubmitting}
              onClick={() => {
                setEventState(EventState.NONE);
                setSelectedEvent(undefined);
              }}
            >
              Cancel
            </button>
          </div>
        )}
        {eventState === EventState.ACTIVE && (
          <div className={"eventAdderWrapper"}>
            <h1>EVENT VERÖFFETNLICHEN</h1>
            {!selectedEvent && (
              <>
                {events?.map((e) => (
                  <>
                    {e.shouldDisplay === 0 && (
                      <button onClick={() => handleActivate(e.id)}>
                        {e.name}
                      </button>
                    )}
                  </>
                ))}
              </>
            )}
            <button
              className={"cancelButton"}
              onClick={() => {
                setEventState(EventState.NONE);
                setSelectedEvent(undefined);
              }}
            >
              Cancel
            </button>
          </div>
        )}
        {eventState === EventState.DEACTIVATE && (
          <div className={"eventAdderWrapper"}>
            <h1>EVENT PRIVAT STELLEN</h1>
            {!selectedEvent && (
              <>
                {events?.map((e) => (
                  <>
                    {e.shouldDisplay === 1 && (
                      <button
                        onClick={() => {
                          axios
                            .put(WEBSITE_URL + "deactivate/" + e.id)
                            .catch((err) => console.log(err));
                        }}
                      >
                        {e.name}
                      </button>
                    )}
                  </>
                ))}
              </>
            )}
            <button
              className={"cancelButton"}
              disabled={isSubmitting}
              onClick={() => {
                setEventState(EventState.NONE);
                setSelectedEvent(undefined);
              }}
            >
              Cancel
            </button>
          </div>
        )}
        {eventState === EventState.EDITBANNER && (
          <div className={"eventAdderWrapper"}>
            <h1>BANNER IMAGE ÄNDERN</h1>
            {!selectedEvent && (
              <>
                {events?.map((e) => (
                  <button onClick={() => setSelectedEvent(e)}>{e.name}</button>
                ))}
              </>
            )}
            {selectedEvent && (
              <EventFormImageOnly
                eventState={eventState}
                setEvents={setEvents}
                eventId={selectedEvent?.id}
                imageName={"Banner Image"}
                buttonName={"Event speichern"}
                partners={partners}
                request={(values, id, image) => {
                  const bannerImage = new FormData();
                  if (image) {
                    bannerImage.append("bannerImage", image, values.eventId);
                    axios({
                      method: "post",
                      url: WEBSITE_URL + "changeBannerImage/" + id,
                      data: bannerImage,
                      headers: { "Content-Type": "multipart/form-data" },
                    })
                      .then((res) => console.log(res))
                      .catch((err) => console.log(err))
                      .finally(() => {
                        //window.location.reload();
                      });
                  } else {
                    //window.location.reload();
                  }
                }}
              />
            )}
            <button
              className={"cancelButton"}
              disabled={isSubmitting}
              onClick={() => {
                setEventState(EventState.NONE);
                setSelectedEvent(undefined);
              }}
            >
              Cancel
            </button>
          </div>
        )}
        {eventState === EventState.EDITSIDEIMG && (
          <div className={"eventAdderWrapper"}>
            <h1>EVENT SIDE IMAGE ÄNDERN</h1>
            {!selectedEvent && (
              <>
                {events?.map((e) => (
                  <button onClick={() => setSelectedEvent(e)}>{e.name}</button>
                ))}
              </>
            )}
            {selectedEvent && (
              <EventFormImageOnly
                eventState={eventState}
                setEvents={setEvents}
                eventId={selectedEvent?.id}
                imageName={"Side Image"}
                buttonName={"Event speichern"}
                partners={partners}
                request={(values, id, image) => {
                  const eventSideImage = new FormData();
                  if (image) {
                    eventSideImage.append(
                      "eventSideImg",
                      image,
                      values.eventId
                    );
                    axios({
                      method: "post",
                      url: WEBSITE_URL + "changeSideImg/" + id,
                      data: eventSideImage,
                      headers: { "Content-Type": "multipart/form-data" },
                    })
                      .then((res) => console.log(res))
                      .catch((err) => console.log(err))
                      .finally(() => {
                        //window.location.reload();
                      });
                  } else {
                    //window.location.reload();
                  }
                }}
              />
            )}
            <button
              className={"cancelButton"}
              disabled={isSubmitting}
              onClick={() => {
                setEventState(EventState.NONE);
                setSelectedEvent(undefined);
              }}
            >
              Cancel
            </button>
          </div>
        )}
        {eventState === EventState.ADDFOTOS && (
          <div className={"eventAdderWrapper"}>
            <h1>FOTOS HINZUFÜGEN</h1>
            {!selectedEvent && (
              <>
                {events?.map((e) => (
                  <button onClick={() => setSelectedEvent(e)}>{e.name}</button>
                ))}
              </>
            )}
            {selectedEvent && (
              <EventFormItem
                submitting={isSubmitting}
                eventState={eventState}
                setEvents={setEvents}
                eventId={selectedEvent?.id}
                eventName={selectedEvent?.name}
                eventDate={selectedEvent?.date}
                eventText={selectedEvent?.text}
                eventType={selectedEvent.type}
                eventPartners={partnersOfEvent.get(selectedEvent.id)}
                eventTicketLink={selectedEvent?.ticketLink}
                buttonName={"Event speichern"}
                partners={partners}
                request={(
                  values,
                  id,
                  bannerImage,
                  eventSideImg,
                  eventImages
                ) => {
                  if (eventImages) {
                    setUploadNumber(eventImages.length);
                    setIsSubmitting(true);
                    uploadImagePart(eventImages, values.eventId, 0);
                  } else {
                    //window.location.reload();
                  }
                }}
              />
            )}
            <button
              className={"cancelButton"}
              disabled={isSubmitting}
              onClick={() => {
                setEventState(EventState.NONE);
                setSelectedEvent(undefined);
              }}
            >
              Cancel
            </button>
            {isSubmitting && (
              <p>
                Upload Progress: {uploadProgress} / {uploadNumber}
              </p>
            )}
          </div>
        )}
        {eventState === EventState.EDITDATA && (
          <div className={"eventAdderWrapper"}>
            <h1>EVENT BEARBEITEN</h1>
            {!selectedEvent && (
              <>
                {events?.map((e) => (
                  <button onClick={() => setSelectedEvent(e)}>{e.name}</button>
                ))}
              </>
            )}
            {selectedEvent && (
              <EventFormItem
                submitting={isSubmitting}
                eventState={eventState}
                setEvents={setEvents}
                eventId={selectedEvent?.id}
                eventName={selectedEvent?.name}
                eventDate={selectedEvent?.date}
                eventText={selectedEvent?.text}
                eventType={selectedEvent.type}
                eventPartners={partnersOfEvent.get(selectedEvent.id)}
                eventTicketLink={selectedEvent?.ticketLink}
                buttonName={"Event speichern"}
                partners={partners}
                request={(
                  values,
                  id,
                  bannerImage,
                  eventSideImg,
                  eventImages
                ) => {
                  axios
                    .post(WEBSITE_URL + "dashboard/" + id, values)
                    .catch((err) => console.log(err));
                }}
              />
            )}
            <button
              className={"cancelButton"}
              disabled={isSubmitting}
              onClick={() => {
                setEventState(EventState.NONE);
                setSelectedEvent(undefined);
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
      <Footer footerTab={"none"} />
    </>
  );
}
