import Navbar from "./Navbar";
import "../styles/BornToBeach.css";
import brauunion from "../assets/logos/brauunion.png";
import redbull from "../../public/partner_logos/redBull.png";
import skruf from "../../public/partner_logos/skruf.png";
import eventImage from "../assets/logos/btb-events.png";
import Footer from "./Footer";
import { EventData } from "./Routes";
import { BornToClubProps } from "./BornToClub";
import EventDisplay from "./EventDisplay";
import { EventType } from "./EventFormItem";
import React, { useState } from "react";
import bteImage from "../assets/logos/btb-events.png";
import SingleNavbar from "./SingleNavbar";
import ScrollToTopButton from "./ScrollToTopButton";

export interface EventsProps {
  events?: EventData[];
  partnersOfEvent: Map<string, string[]>;
  logo: string;
  loadFinished: boolean;
}

export default function Events({
  events,
  partnersOfEvent,
  logo,
  loadFinished,
}: EventsProps) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <div className={"background"}>
      <SingleNavbar
        directInstagramLink={true}
        selectedTab={"events"}
        imageUrl={logo}
        extended={isNavExpanded}
        onExtendedChange={(val) => setIsNavExpanded(val)}
      ></SingleNavbar>
      <ScrollToTopButton />
      {!isNavExpanded && (
        <div className={"eventsWrapper"}>
          <div className={"card bornToClubMain columnFlex"}>
            <div className={"cardHeader"}>Upcoming Events</div>
            <EventDisplay
              events={events}
              type={EventType.event}
              size={"full"}
              eventTime={"future"}
              partnersOfEvent={partnersOfEvent}
              loadingFinished={loadFinished}
              minimizeIfNone={true}
            />
          </div>
          <div className={"card bornToClubMain columnFlex"}>
            <div className={"cardHeader"}>Vergangene Events</div>
            <EventDisplay
              partnersOfEvent={partnersOfEvent}
              events={events}
              type={EventType.event}
              size={"full"}
              eventTime={"past"}
              loadingFinished={loadFinished}
            />
          </div>
        </div>
      )}
      <Footer footerTab={"none"} />
    </div>
  );
}
