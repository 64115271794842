import Navbar from "./Navbar";
import "../styles/BornToBeach.css";
import brauunion from "../assets/logos/brauunion.png";
import redbull from "../../public/partner_logos/redBull.png";
import skruf from "../../public/partner_logos/skruf.png";
import btbImage from "../assets/logos/btb.png";
import AboutImage from "../assets/random/placeholder1.jpg";
import Footer from "./Footer";
import SingleNavbar from "./SingleNavbar";
import React, { useState } from "react";
import ScrollToTopButton from "./ScrollToTopButton";

export interface ImpressumProps {
  logo: string;
}

export default function Impressum({ logo }: ImpressumProps) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <div className={"background"}>
      <SingleNavbar
        selectedTab={"none"}
        imageUrl={btbImage}
        extended={isNavExpanded}
        onExtendedChange={(val) => setIsNavExpanded(val)}
      ></SingleNavbar>
      <ScrollToTopButton />

      <div className={"infoWrapper"}>
        <div className={"infoCard"}>
          <div className={"infoHeader"}>IMPRESSUM</div>
          <div className={"infoContent"}>
            <b>Unternehmen:</b>
            <br />
            Born To Beach <br />
            Antonio Rosa Einzelunternehmen <br />
            <br />
            <b>Adresse:</b> <br />
            Seeweingärten III / 14 <br />
            7141 Podersdorf am See <br />
            <br />
            <b>Mail: </b> ar@pinkandblack.at
            <br />
            <b>UID-Nr:</b> ATU75649559
          </div>
          <div className={"infoHeader"}>Urheberrecht</div>
          <p>
            Die Inhalte dieser Webseite unterliegen, soweit dies rechtlich
            möglich ist, diversen Schutzrechten (z.B dem Urheberrecht). Jegliche
            Verwendung/Verbreitung von bereitgestelltem Material, welche
            urheberrechtlich untersagt ist, bedarf schriftlicher Zustimmung des
            Webseitenbetreibers.
          </p>
          <div className={"infoHeader"}>Haftungsausschluss</div>
          <p>
            Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der
            Webseitenbetreiber dieser Webseite keine Haftung für die Inhalte
            externer Links. Für den Inhalt der verlinkten Seiten sind
            ausschließlich deren Betreiber verantwortlich. Sollten Sie dennoch
            auf ausgehende Links aufmerksam werden, welche auf eine Webseite mit
            rechtswidriger Tätigkeit/Information verweisen, ersuchen wir um
            dementsprechenden Hinweis, um diese nach § 17 Abs. 2 ECG umgehend zu
            entfernen.
            <br />
            Die Urheberrechte Dritter werden vom Betreiber dieser Webseite mit
            größter Sorgfalt beachtet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden derartiger
            Rechtsverletzungen werden wir den betroffenen Inhalt umgehend
            entfernen.
          </p>
        </div>
      </div>
      <Footer footerTab={"impressum"} />
    </div>
  );
}
