import "../styles/BornToBeach.css";
import Footer from "./Footer";
import React, { useState } from "react";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import { Navigate, useNavigate } from "react-router-dom";
import btbImage from "../assets/logos/btb.png";
import axios from "axios";
import { WEBSITE_URL } from "./Routes";
import SingleNavbar from "./SingleNavbar";

export interface LoginProps {
  type: "admin" | "scan-qr" | "send-qr";
  redirectTo: string;
}

export default function Login({ type, redirectTo }: LoginProps) {
  const isAuthenticated = useIsAuthenticated();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  /**
   * Login Handle, the callback function onClick from the "Login" button
   *
   * This function demostrate a dummy authentication, using useSignIn function
   */
  const loginHandler = () => {
    //axios request check password -> then do this
    axios
      .post(WEBSITE_URL + "login", {
        username: username,
        password: password,
      })

      .then((response) => {
        const rechte = response.data.rechte;
        let hasEnoughRights = false;
        if (rechte === "admin") {
          hasEnoughRights = true;
        } else if (type === "send-qr" && rechte === "sendQR") {
          hasEnoughRights = true;
        } else if (type === "scan-qr" && rechte === "scanQR") {
          hasEnoughRights = true;
        }
        if (response.status === 200 && hasEnoughRights) {
          if (
            signIn({
              token: "35v3443bn368367n306306wbn407qn420b436b4", //Just a random token
              tokenType: "Bearer", // Token type set as Bearer
              authState: {
                rights: response.data.rechte,
              }, // Dummy auth user state
              expiresIn: 120, // Token Expriration time, in minutes
            })
          ) {
            navigate(redirectTo);
          } else {
            alert("Error Occoured. Try Again");
          }
        } else if (!hasEnoughRights) {
          alert("Keine Rechte um auf diese Seite zuzugreifen!");
        }
      })
      .catch((err) => console.log(err));
  };
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  if (isAuthenticated()) {
    return <Navigate to={redirectTo} replace />;
  } else {
    return (
      <div className={"background"}>
        <SingleNavbar
          selectedTab={"none"}
          imageUrl={btbImage}
          extended={isNavExpanded}
          onExtendedChange={(val) => setIsNavExpanded(val)}
        ></SingleNavbar>
        {!isNavExpanded && (
          <div className={"loginWrapper"}>
            <form
              className="loginForm"
              onSubmit={(e) => {
                e.preventDefault();
                loginHandler();
              }}
              method={"post"}
            >
              <div className="input-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label htmlFor="password">Passwort</label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button className="primary loginButton">Login</button>
            </form>
          </div>
        )}
        <Footer footerTab={"none"} />
      </div>
    );
  }
}
