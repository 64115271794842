import "../styles/Navbar.css";
import ReactDatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import { EventData, PartnerData } from "./Routes";
import { v4 as uuidv4 } from "uuid";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { SelectItem } from "primereact/selectitem";
import React, { ChangeEvent, useMemo, useState } from "react";
import { EventState } from "./Dashboard";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export enum EventType {
  beach = "beach",
  event = "event",
}

export interface EventFormItemProps {
  eventName?: string;
  eventDate?: string;
  eventText?: string;
  eventType?: string;
  eventPartners?: string[];
  eventTicketLink?: string;
  eventBanner?: File;
  eventSideImage?: File;
  eventImageList?: FileList;
  eventId?: string;
  request?: (
    values: FormikValues,
    id?: string,
    bannerImage?: File,
    eventSideImg?: File,
    eventImages?: FileList
  ) => void;
  buttonName?: string;
  setEvents?: (events: EventData[]) => void;
  partners?: PartnerData[];
  eventState: EventState;
  submitting: boolean;
}

export default function EventFormItem({
  eventName,
  submitting,
  eventDate,
  eventText,
  eventType,
  eventId,
  request,
  eventTicketLink,
  buttonName,
  setEvents,
  eventSideImage,
  eventPartners,
  partners,
  eventState,
  eventBanner,
  eventImageList,
}: EventFormItemProps) {
  const SignupSchema = Yup.object().shape({
    eventName: Yup.string().required("Required"),
    eventDate: Yup.string().required("Required"),
    eventText: Yup.string().required("Required"),
  });

  const [bannerImage, setBannerImage] = useState<File | undefined>(
    eventBanner || undefined
  );
  const [eventSideImg, setEventSideImage] = useState<File | undefined>(
    eventSideImage || undefined
  );
  const handleBannerImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setBannerImage(e.target.files[0]);
    }
  };

  const handleEventSideImg = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setEventSideImage(e.target.files[0]);
    }
  };
  const [eventImages, setEventImages] = useState<FileList | undefined>(
    eventImageList || undefined
  );
  const handleEventImagesChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setEventImages(e.target.files);
    }
  };

  let realDate: string | undefined = undefined;
  if (eventDate) {
    realDate = DateTime.fromISO(eventDate).toISODate();
  }

  const options = useMemo(() => {
    const options: SelectItem[] = [];
    partners?.forEach((p) => {
      options.push({ label: p.name, value: p.name });
    });
    return options;
  }, [partners]);

  return (
    <Formik
      validationSchema={SignupSchema}
      initialValues={{
        eventId: eventId ? eventId : uuidv4(),
        eventName: eventName,
        eventDate: realDate,
        eventText: eventText,
        eventBanner: eventBanner,
        eventImages: eventImages,
        eventType: eventType ? eventType : EventType.event,
        eventPartners: eventPartners,
        eventTicketLink: eventTicketLink,
        eventSideImg: eventSideImage,
      }}
      onSubmit={(values, { setSubmitting }) => {
        request &&
          request(values, eventId, bannerImage, eventSideImg, eventImages);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setValues,
        isValid,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form className="form" onSubmit={handleSubmit}>
          {(eventState === EventState.ADD ||
            eventState === EventState.EDITDATA) && (
            <>
              <div className={"formRow"}>
                <div className="input-group">
                  <label htmlFor="eventName">Eventname</label>
                  <input
                    disabled={submitting}
                    type="text"
                    name="eventName"
                    placeholder="Eventname"
                    value={values.eventName}
                    onChange={handleChange}
                  />
                  <div className={"eventErrorText"}>
                    {errors.eventName && touched.eventName && errors.eventName}
                  </div>
                </div>
                <div className="input-group">
                  <label htmlFor="eventDate">Datum</label>
                  <ReactDatePicker
                    disabled={submitting}
                    name={"eventDate"}
                    placeholderText={"MM/dd/YYYY"}
                    className={"datePicker"}
                    value={values.eventDate}
                    onChange={(date) => {
                      if (date) {
                        setValues({
                          ...values,
                          eventDate: DateTime.fromJSDate(date).toISODate(),
                        });
                      }
                    }}
                  />
                  <div className={"eventErrorText"}>
                    {errors.eventDate && touched.eventDate && (
                      <>{errors.eventDate}</>
                    )}
                  </div>
                </div>
                <div className={"input-group"}>
                  <label htmlFor="eventType">Type</label>
                  <select
                    disabled={submitting}
                    name={"eventType"}
                    onChange={handleChange}
                    value={values.eventType}
                    onBlur={handleBlur}
                    defaultValue={EventType.event}
                  >
                    <option key={EventType.event} value={EventType.event}>
                      {EventType.event}
                    </option>
                    <option key={EventType.beach} value={EventType.beach}>
                      {EventType.beach}
                    </option>
                  </select>
                  <div className={"eventErrorText"}>
                    {errors.eventType && touched.eventType && errors.eventType}
                  </div>
                </div>
              </div>
              <div className={"formRow"}>
                <div className="input-group">
                  <label htmlFor="eventId">URL ID</label>
                  <input
                    disabled={submitting}
                    type="text"
                    name="eventId"
                    placeholder="Eventname"
                    value={values.eventId}
                    onChange={handleChange}
                  />
                  <div className={"eventErrorText"}>
                    {errors.eventId && touched.eventId && errors.eventId}
                  </div>
                </div>
                <div className={"input-group"}>
                  <label htmlFor="eventTicketLink">Ticket Link</label>
                  <input
                    disabled={submitting}
                    type="text"
                    name="eventTicketLink"
                    placeholder="Ticket Link"
                    value={values.eventTicketLink}
                    onChange={handleChange}
                  />
                  <div className={"eventErrorText"}>
                    {errors.eventTicketLink &&
                      touched.eventTicketLink &&
                      errors.eventTicketLink}
                  </div>
                </div>
                {/*
            <div className="input-group">
              <label htmlFor="eventPartners">Event Partner</label>
              <MultiSelect
                value={values.eventPartners}
                onChange={(e) => {
                  setValues({ ...values, eventPartners: e.value });
                }}
                options={options}
                optionLabel={"label"}
                placeholder={"Partner auswählen"}
                display={"chip"}
                color={"black"}
              />
              <div className={"eventErrorText"}>
                {errors.eventPartners &&
                  touched.eventPartners &&
                  errors.eventPartners}
              </div>
            </div>
            */}
              </div>
              <div className={"formRow"}>
                <div className="input-group">
                  <>
                    <label htmlFor="eventText">Text</label>
                    <CKEditor
                      disabled={submitting}
                      editor={ClassicEditor}
                      data={values.eventText}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setValues({
                          ...values,
                          eventText: data,
                        });
                      }}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                    />
                    <div className={"eventErrorText"}>
                      {errors.eventText &&
                        touched.eventText &&
                        errors.eventText}
                    </div>
                  </>
                </div>
              </div>
              {eventState !== EventState.EDITDATA && (
                <div className={"formRow"}>
                  <div className="input-group">
                    <label htmlFor="eventBanner">Banner Image</label>
                    <input
                      disabled={submitting}
                      type="file"
                      name="eventBanner"
                      onChange={(e) => {
                        handleBannerImageChange(e);
                        if (e.target.files !== null && e.target.files[0]) {
                          setFieldValue("eventBanner", e.target.files[0]);
                        }
                      }}
                    />
                    <label htmlFor="eventSideImg">Event Info Image</label>
                    <input
                      disabled={submitting}
                      type="file"
                      name="eventSideImg"
                      onChange={(e) => {
                        handleEventSideImg(e);
                        if (e.target.files !== null && e.target.files[0]) {
                          setFieldValue("eventSideImg", e.target.files[0]);
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {eventState === EventState.ADDFOTOS && (
            <div className="input-group">
              <label htmlFor="eventImages">Event Images</label>
              <input
                disabled={submitting}
                multiple={true}
                type="file"
                name="eventImages"
                onChange={(e) => {
                  handleEventImagesChange(e);
                  if (e.target.files !== null && e.target.files.length > 0) {
                    setFieldValue("eventImages", e.target.files);
                  }
                }}
              />
            </div>
          )}
          <div className={"formRow"}>
            <button className="primary" disabled={submitting}>
              {buttonName}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}
