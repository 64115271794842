import { Link } from "react-router-dom";
import "../styles/Navbar.css";
import { useState } from "react";
import Facebook from "../assets/icons/facebook-f-brands.svg";
import Instagram from "../assets/icons/instagram-brands.svg";
import InstagramXMark from "../assets/icons/xmark-solid-ig.svg";

export interface NavbarProps {
  imageUrl: string;
  selectedTab: "home" | "club" | "events" | "about" | "contact" | "none";
}

export default function Navbar({ imageUrl, selectedTab }: NavbarProps) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [displayInstagram, setDisplayInstagram] = useState(false);
  return (
    <div>
      <nav className="navigation">
        <div className={"navigationContent"}>
          <div className={"logoImageWrapper"}>
            <Link className={"link instagramLink"} to={"/"}>
              <img
                src={imageUrl}
                className={`logoImage ${
                  selectedTab === "home" ||
                  selectedTab === "about" ||
                  selectedTab === "contact" ||
                  selectedTab === "none"
                    ? "btb"
                    : ""
                }`}
              />
            </Link>
          </div>
          <button
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }
          >
            <div className={"linkWrapper"} data-staticsize={"club"}>
              {displayInstagram && (
                <Link
                  className={"link instagramLink"}
                  to={"https://www.instagram.com/borntobeach.club/"}
                >
                  Beach Club
                </Link>
              )}
              {!displayInstagram && (
                <Link
                  className={`link ${selectedTab === "club" ? "selected" : ""}`}
                  to={"/beachClub"}
                >
                  Beach Club
                </Link>
              )}
            </div>

            <div className={"linkWrapper"} data-staticsize={"events"}>
              {displayInstagram && (
                <Link
                  className={"link instagramLink"}
                  to={"https://www.instagram.com/borntobeach.events/"}
                >
                  Events
                </Link>
              )}
              {!displayInstagram && (
                <Link
                  className={`link ${
                    selectedTab === "events" ? "selected" : ""
                  }`}
                  to={"/events"}
                >
                  Events
                </Link>
              )}
            </div>
            <div
              className={`linkWrapper ${displayInstagram ? "transparent" : ""}`}
              data-staticsize={"about"}
            >
              <Link
                className={`link ${selectedTab === "about" ? "selected" : ""}`}
                to={"/about"}
              >
                Ueber uns
              </Link>
            </div>
            <div
              className={`linkWrapper ${displayInstagram ? "transparent" : ""}`}
              data-staticsize={"contact"}
            >
              <Link
                className={`link ${
                  selectedTab === "contact" ? "selected" : ""
                }`}
                to={"/contact"}
              >
                Kontakt
              </Link>
            </div>
          </div>
          <div className={"socialWrapper"}>
            <Link
              className={"socialButton"}
              to="https://www.facebook.com/BornToBeach.Events.BeachClub"
              target="_blank"
            >
              <img className={"socialIcon"} src={Facebook} alt={"Fb"} />
            </Link>
            <button
              className={"socialButton"}
              onClick={() => {
                setDisplayInstagram(!displayInstagram);
              }}
            >
              <img
                className={"socialIcon"}
                src={displayInstagram ? InstagramXMark : Instagram}
                alt={"Ig"}
              />
            </button>
          </div>
        </div>
      </nav>
      <div className={"spacer"}></div>
    </div>
  );
}
