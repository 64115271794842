import Navbar from "./Navbar";
import "../styles/BornToBeach.css";
import brauunion from "../assets/logos/brauunion.png";
import redbull from "../../public/partner_logos/redBull.png";
import skruf from "../../public/partner_logos/skruf.png";
import AboutImage from "../assets/random/placeholder1.jpg";
import Footer from "./Footer";
import React, { useCallback, useEffect, useState } from "react";
import SingleNavbar from "./SingleNavbar";
import ScrollToTopButton from "./ScrollToTopButton";
import axios from "axios";
import { WEBSITE_URL } from "./Routes";
import { Scanner } from "@yudiel/react-qr-scanner";
import { QrCodeData } from "./SendQRCodes";
import { useNavigate } from "react-router-dom";

export interface AboutProps {
  logo: string;
}

export default function ScanQr({ logo }: AboutProps) {
  const [validQrCodes, setValidQrCodes] = useState<QrCodeData[]>([]);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [displayCorrectQrCode, setDisplayCorrectQrCode] =
    useState<boolean>(false);
  const [displayWrongQrCode, setDisplayWrongQrCode] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [ticketsToUse, setTicketsToUse] = useState<number>(1);

  const [scannedQRCode, setScannedQrCode] = useState<QrCodeData | undefined>(
    undefined
  );

  const [hasPermission, setHasPermission] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    // Check if camera permission is already granted
    const checkCameraPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
          setHasPermission(true);
        }
      } catch (error) {
        setHasPermission(false);
      }
    };

    // Check if permission is granted or not
    if (hasPermission === undefined) {
      checkCameraPermission();
    }
  }, [hasPermission]);

  const doShow = useCallback((dialog: HTMLDialogElement | null) => {
    if (dialog && !dialog.open) {
      dialog.showModal();
    }
  }, []);

  useEffect(() => {
    axios
      .get(WEBSITE_URL + "qr-codes")
      .then((response) => {
        setValidQrCodes(response.data);
      })
      .catch((err) => {
        alert("ERROR OCCURRED!");
        console.log(err);
      });
  }, []);

  const updateQrCodeUsed = useCallback((id: string, ticketsLeft: number) => {
    if (ticketsLeft < 0) {
      alert("Mehr tickets eingelöst als vorhanden!");
      return;
    }
    setIsSubmitting(true);
    axios
      .put(WEBSITE_URL + "qr-code-used/" + id + "/" + ticketsLeft)
      .then(() => {
        setIsSubmitting(false);
        setDisplayDialog(false);
        setScannedQrCode(undefined);
        setDisplayCorrectQrCode(false);
        setDisplayWrongQrCode(false);
        axios
          .get(WEBSITE_URL + "qr-codes")
          .then((response) => {
            setValidQrCodes(response.data);
          })
          .catch((err) => {
            alert("ERROR OCCURRED!");
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className={"background"}>
      <SingleNavbar
        selectedTab={"none"}
        imageUrl={logo}
        extended={isNavExpanded}
        onExtendedChange={(val) => setIsNavExpanded(val)}
      ></SingleNavbar>

      <ScrollToTopButton />

      {displayDialog && scannedQRCode && (
        <dialog
          ref={doShow}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <p>QR Code gültig!</p>
          <p>
            {" "}
            KATEGORIE: <b>{scannedQRCode.category}</b>
          </p>
          <p>Tickets verfügbar: {scannedQRCode.ticketsLeft}</p>

          <div>
            <label>Verwendete Tickets</label>
            <input
              type={"number"}
              placeholder={"Anzahl"}
              defaultValue={1}
              max={scannedQRCode.ticketsLeft}
              min={1}
              value={ticketsToUse}
              onChange={(event) =>
                setTicketsToUse(parseInt(event.target.value))
              }
              style={{ width: "100%" }}
            ></input>
          </div>
          <br />
          <button
            style={{ marginBottom: "1rem" }}
            disabled={isSubmitting}
            onClick={() =>
              updateQrCodeUsed(
                scannedQRCode.id,
                scannedQRCode.ticketsLeft - ticketsToUse
              )
            }
          >
            Ticket(s) einlösen
          </button>
          <button
            disabled={isSubmitting}
            onClick={() => {
              setIsSubmitting(false);
              setDisplayDialog(false);
              setScannedQrCode(undefined);
              setDisplayCorrectQrCode(false);
              setDisplayWrongQrCode(false);
            }}
          >
            Cancel
          </button>
        </dialog>
      )}
      <div className={"eventAdderWrapper"}>
        <h1>QR CODE SCANNEN</h1>
        <div className={"scanWrapper"}>
          {!scanning && (
            <button
              onClick={() => setScanning(true)}
              disabled={validQrCodes.length === 0}
            >
              Scan starten
            </button>
          )}
          {scanning && (
            <Scanner
              onResult={(text, result) => {
                setDisplayCorrectQrCode(false);
                setDisplayWrongQrCode(false);
                let decryptedCode = result.getText();
                let isValidCode = false;
                let validCode = undefined;
                for (let validQrCode of validQrCodes) {
                  if (
                    validQrCode.id === decryptedCode &&
                    validQrCode.ticketsLeft > 0
                  ) {
                    validCode = validQrCode;
                    isValidCode = true;
                    break;
                  }
                }
                if (isValidCode) {
                  setDisplayCorrectQrCode(true);
                  setScannedQrCode(validCode);
                  setDisplayDialog(true);
                  setScanning(false);
                } else {
                  setDisplayWrongQrCode(true);
                }
              }}
              onError={(error) => {
                console.log(error?.message);
                setDisplayWrongQrCode(true);
              }}
            />
          )}
        </div>
        {displayCorrectQrCode && (
          <p style={{ color: "green" }}>QR Code gültig</p>
        )}
        {displayWrongQrCode && scanning && (
          <p style={{ color: "red" }}>QR Code UNGÜLTIG</p>
        )}
        {scanning && (
          <button
            className={"cancelButton"}
            disabled={isSubmitting}
            onClick={() => setScanning(false)}
          >
            Cancel
          </button>
        )}
      </div>

      <Footer footerTab={"none"} />
    </div>
  );
}
