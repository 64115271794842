import Navbar from "./Navbar";
import "../styles/BornToBeach.css";
import brauunion from "../assets/logos/brauunion.png";
import redbull from "../../public/partner_logos/redBull.png";
import skruf from "../../public/partner_logos/skruf.png";
import btbImage from "../assets/logos/btb.png";
import AboutImage from "../assets/random/placeholder1.jpg";
import Footer from "./Footer";
import btcImage from "../assets/logos/btb-club.png";
import SingleNavbar from "./SingleNavbar";
import React, { useState } from "react";
import ScrollToTopButton from "./ScrollToTopButton";

export interface DatenschutzProps {
  logo: string;
}

export default function Datenschutz({ logo }: DatenschutzProps) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <div className={"background"}>
      <SingleNavbar
        selectedTab={"none"}
        imageUrl={btbImage}
        extended={isNavExpanded}
        onExtendedChange={(val) => setIsNavExpanded(val)}
      ></SingleNavbar>
      <ScrollToTopButton />

      <div className={"infoWrapper"}>
        <div className={"infoCard"}>
          <div className={"infoHeader"}>DATENSCHUTZERKLÄRUNG</div>
          <div className={"infoContent"}>
            In folgender Datenschutzerklärung informieren wir Sie über die
            wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer
            Webseite. Wir erheben und verarbeiten personenbezogene Daten nur auf
            Grundlage der gesetzlichen Bestimmungen (Datenschutzgrundverordnung,
            Telekommunikationsgesetz 2003).
          </div>
          <div className={"infoContent"}>
            Sobald Sie als Benutzer auf unsere Webseite zugreifen oder diese
            besuchen wird Ihre IP-Adresse, Beginn sowie Beginn und Ende der
            Sitzung erfasst. Dies ist technisch bedingt und stellt somit ein
            berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO.{" "}
          </div>

          <div className={"infoHeader"}>Kontakt mit uns</div>
          <div className={"infoContent"}>
            Wenn Sie uns, entweder über unser Kontaktformular auf unserer
            Webseite, oder per Email kontaktieren, dann werden die von Ihnen an
            uns übermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder für
            den Fall von weiteren Anschlussfragen für sechs Monate bei uns
            gespeichert. Es erfolgt, ohne Ihre Einwilligung, keine Weitergabe
            Ihrer übermittelten Daten.
          </div>
          <div className={"infoHeader"}>Cookies</div>
          <div className={"infoContent"}>
            Unsere Website verwendet so genannte Cookies. Dabei handelt es sich
            um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät
            abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies
            dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies
            bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie
            ermöglichen es uns, Ihren Browser beim nächsten Besuch
            wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren
            Browser so einrichten, dass er Sie über das Setzen von Cookies
            informiert und Sie dies nur im Einzelfall erlauben. Bei der
            Deaktivierung von Cookies kann die Funktionalität unserer Website
            eingeschränkt sein.
          </div>
          <div className={"infoHeader"}>Server-Log Files</div>
          <div className={"infoContent"}>
            Diese Webseite und der damit verbundene Provider erhebt im Zuge der
            Webseitennutzung automatisch Informationen im Rahmen sogenannter
            „Server-Log Files“. Dies betrifft insbesondere:{" "}
            <ul>
              <li> IP-Adresse oder Hostname</li>
              <li> den verwendeten Browser</li>
              <li>
                {" "}
                Aufenthaltsdauer auf der Webseite sowie Datum und Uhrzeit
              </li>
              <li> aufgerufene Seiten der Webseite</li>
              <li> Spracheinstellungen und Betriebssystem</li>
              <li>
                {" "}
                „Leaving-Page“ (auf welcher URL hat der Benutzer die Webseite
                verlassen)
              </li>
              <li> ISP (Internet Service Provider)</li>
            </ul>
            Diese erhobenen Informationen werden nicht personenbezogen
            verarbeitet oder mit personenbezogenen Daten in Verbindung gebracht.
          </div>
          <div className={"infoContent"}>
            Der Webseitenbetreiber behält es sich vor, im Falle von
            Bekanntwerden rechtswidriger Tätigkeiten, diese Daten auszuwerten
            oder zu überprüfen.
          </div>
          <div className={"infoHeader"}>Ihre Rechte als Betroffener</div>
          <div className={"infoContent"}>
            Sie als Betroffener haben bezüglich Ihrer Daten, welche bei uns
            gespeichert sind grundsätzlich ein Recht auf:
            <ul>
              <li>Auskunft</li>
              <li>Löschung der Daten</li>
              <li>Berichtigung der Daten</li>
              <li>Übertragbarkeit der Daten</li>
              <li>Wiederruf und Widerspruch zur Datenverarbeitung</li>
              <li>Einschränkung</li>
            </ul>
            Wenn sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten
            Verstöße gegen das Datenschutzrecht passiert sind, so haben Sie die
            Möglichkeit sich bei uns (gastro@borntobeach.at) oder der
            Datenschutzbehörde zu beschweren.
          </div>
          <div className={"infoHeader"}>
            Sie erreichen uns unter folgenden Kontaktdaten:
          </div>
          <div className={"infoContent"}>
            <b>Webseitenbetreiber:</b> Antonio Rosa
            <br /> <b>Email:</b> gastro@borntobeach.at <br /> <br />
            Quelle:{" "}
            <b>
              <a
                href="https://www.rechtstexte-generator.at/dsgvo-generator-oesterreich/"
                style={{ color: "black", fontWeight: "normal" }}
              >
                Datenschutz DSGVO Generator
              </a>
            </b>{" "}
            in Kooperation mit{" "}
            <b>
              <a
                href="http://ortner-rechtsanwalt.at/"
                style={{ color: "black", fontWeight: "normal" }}
              >
                Rechtsanwalt Wien Ortner
              </a>
            </b>
          </div>
        </div>
      </div>
      <Footer footerTab={"datenschutz"} />
    </div>
  );
}
