import Navbar from "./Navbar";
import "../styles/BornToBeach.css";
import brauunion from "../assets/logos/brauunion.png";
import redbull from "../../public/partner_logos/redBull.png";
import skruf from "../../public/partner_logos/skruf.png";
import AboutImage from "../assets/random/placeholder1.jpg";
import Footer from "./Footer";
import React, { useState } from "react";
import btbImage from "../assets/logos/btb.png";
import SingleNavbar from "./SingleNavbar";
import ScrollToTopButton from "./ScrollToTopButton";
import { Carousel } from "react-responsive-carousel";
import {
  faArrowLeft,
  faArrowRight,
  faBars,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface AboutProps {
  logo: string;
}

export default function About({ logo }: AboutProps) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <div className={"background"}>
      <SingleNavbar
        selectedTab={"about"}
        imageUrl={logo}
        extended={isNavExpanded}
        onExtendedChange={(val) => setIsNavExpanded(val)}
      ></SingleNavbar>
      <ScrollToTopButton />
      {!isNavExpanded && (
        <>
          <div className={"card bornToClubMain columnFlex"}>
            <div className={"cardHeader"}>Das ist Born To Beach</div>
            <div className={"aboutWrapper"}>
              <div>
                Das Konzept der Born To Beach Events entstand bereits 2018.
                Unser größtes Ziel ist es, die burgenländische Jugend- und
                Musikkultur zu fördern. Müsste man unsere Vision in Worte
                fassen, könnte man sagen, dass wir eines Tages große Festivals
                für Österreicher:innen veranstalten wollen. Außerdem möchten wir
                Jugendlichen und Junggebliebenen mit unseren Events eine
                Möglichkeit bieten, dem Alltag zu entfliehen, alles hinter sich
                zu lassen und einfach mal abzuschalten. Die Veranstaltungen
                sollen eine gute Zeit mit seinen besten Freund:innen und neuen
                Erlebnissen sowie Eindrücken verschaffen. Unser junges Team
                steckt voller Energie, Euphorie und Motivation um intensiv an
                dieser Vision zu arbeiten. Dies spiegelt sich auch in unseren
                Produktionen wider - sei es Gastro oder Bühne, wir geben immer
                110%. Wir haben es bereits geschafft, die größten nationalen DJs
                sowie auch internationale DJs auftreten zu lassen und wollen uns
                diesbezüglich jährlich steigern. Eines Tages sollen im
                Burgenland Star-DJs auf unfassbar coolen Bühnen auftreten. Auch
                unsere Bühnenproduktionen in Zusammenarbeit mit Dropit.fm haben
                bereits ein Level erreicht, welches für sich spricht.
                Besucher:innen behaupten, dass es solch festivalartige Bühnen
                sonst nie im Burgenland gebe. Über dieses Feedback freuen wir
                uns natürlich besonders, aber: „Wir san no laung ned do, wo ma
                hiwön” – Antonio Rosa an Fabio Edler via Sprachmemo auf WhatsApp
                😄
                <br /> <br />
                2020 gab es, wie für die gesamte Weltbevölkerung, eine neue,
                aufregende Herausforderung, wie wir es nannten um positiv zu
                blieben. Plötzlich konnten wir unserer Liebe, dem Veranstalten,
                nicht mehr nachgehen. Nichtsdestotrotz fanden wir eine Lösung,
                diese etwas schwierigere Zeit zu überbrücken. „Jungs, wos dama?“
                🙄 Der BEACH CLUB entstand. 2020 noch völlig anders als man ihn
                jetzt kennt. Eine ganz einfache „Containerbar“ wie man sie von
                der Donauinsel kannte, 56qm Sand von Lisa Altenburger (😘),
                Liegestühle von kronehit, unsere Local DJs und ein Team, das
                noch motivierter denn je war. 2020 und 2021 schafften wir also
                eine für uns neue Art von Rückzugsort für viele
                Seewinkler:innen. Den Bestimmungen entsprechend mussten wir
                teilweise sogar Besucher:innen nach Hause schicken, um eben den
                damals geltenden Regelungen zu entsprechen. Von Jahr zu Jahr, in
                dem wir keine Events veranstalten konnten, wurde die Liebe zu
                unserem BEACH CLUB immer größer und wir wussten, dass das eine
                langfristige Beziehung werden würde. Dementsprechend
                investierten wir immer mehr Zeit, Liebe und auch finanzielle
                Mittel in den Club. 2023 war dann unsere erste Saison, wo wir
                den BEACH CLUB so präsentieren konnten, wie wir ihn uns
                vorgestellt hatten. Naja, so ungefähr zumindest, wenn ich an
                Antonios Sprachmemos um 00.23 Uhr in der Nacht denke. Da kommt
                wohl doch noch ein Stück Arbeit auf uns zu, bis wir „DEN BEACH
                CLUB in Österreich“ führen. Jedenfalls merkten wir auch an den
                Besucherzahlen, dass sich die Qualität in eine gute Richtung
                entwickelt & wir jetzt noch ein Stückchen intensiver daran
                arbeiten werden. <br /> <br /> Wir sind sehr gespannt, wo uns
                die Reise mit Born To Beach hinführen wird. Ziemlich aufgeregt
                sogar. In den Schlussworten möchten wir uns bei unserer Born To
                Beach FAMILY , bei allen Partnern, Mitarbeiter:innen,
                Helfer:innen, Freund:innen und Familienmitgliedern herzlichst
                bedanken, die teils seit Tag 1 hinter uns stehen. Danke für Eure
                Unterstützung, ohne euch wären wir nicht Born To Beach! <br />
                <br />
                Euer Antonio, Eure Sabi, Euer Fabio 💙
              </div>
            </div>
            <div className={"cardHeader"}>Unsere Partner</div>
            <div className={"partnerWrapper"}>
              <Carousel
                showStatus={false}
                showThumbs={false}
                className={"imageCarousel"}
                autoPlay={false}
                axis={"horizontal"}
                showArrows={true}
                stopOnHover
                infiniteLoop={true}
                renderArrowPrev={(clickHandler, hasPrev) => {
                  return (
                    <div
                      className={`${hasPrev ? "absolute" : "hidden"} arrow`}
                      onClick={clickHandler}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} color={"black"} />
                    </div>
                  );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                  return (
                    <div
                      className={`${
                        hasNext ? "absolute" : "hidden"
                      } arrow right`}
                      onClick={clickHandler}
                    >
                      <FontAwesomeIcon icon={faArrowRight} color={"black"} />
                    </div>
                  );
                }}
              >
                <div className={"partnerRow"}>
                  <img
                    src={"/partner_logos/bacardi-png.png"}
                    className={"partnerLogo"}
                  />
                  <img
                    className={"partnerLogo"}
                    src={"/partner_logos/dropit_black.png"}
                  />
                  <img
                    className={"partnerLogo"}
                    src={"/partner_logos/podo_transparent.png"}
                  />
                </div>
                <div className={"partnerRow"}>
                  <img
                    className={"partnerLogo"}
                    src={"/partner_logos/zonex.png"}
                  />
                  <img
                    className={"partnerLogo"}
                    src={"/partner_logos/sol-png.png"}
                  />
                  <img
                    className={"partnerLogo"}
                    src={"/partner_logos/redbull-png.png"}
                  />
                </div>
                <div className={"partnerRow"}>
                  <img
                    className={"partnerLogo"}
                    src={"/partner_logos/eristoff_png.png"}
                  />
                  <img
                    className={"partnerLogo"}
                    src={"/partner_logos/heineken_png.png"}
                  />
                  <img
                    className={"partnerLogo"}
                    src={"/partner_logos/AC_staff_v2.png"}
                  />
                </div>
              </Carousel>
            </div>
          </div>
        </>
      )}
      <Footer footerTab={"none"} />
    </div>
  );
}
