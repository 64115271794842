import Navbar from "./Navbar";
import "../styles/BornToBeach.css";
import brauunion from "../assets/logos/brauunion.png";
import redbull from "../../public/partner_logos/redBull.png";
import skruf from "../../public/partner_logos/skruf.png";
import btbImage from "../assets/logos/btb.png";
import AboutImage from "../assets/random/placeholder1.jpg";
import { EventData, WEBSITE_URL } from "./Routes";
import { BornToBeachProps } from "./BornToBeach";
import SingleNavbar from "./SingleNavbar";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Footer from "./Footer";
import ScrollToTopButton from "./ScrollToTopButton";
import axios from "axios";
import AgenturNavbar from "./AgenturNavbar";
import EventDisplay from "./EventDisplay";
import { EventType } from "./EventFormItem";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

export interface AgenturProps {
  singleNavbar: boolean;
  logo: string;
}

export default function Agentur({ singleNavbar, logo }: AgenturProps) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const [isSecondNavbarFixed, setIsSecondNavbarFixed] = useState(false);
  let element = document.getElementById("agenturCard");
  const zeltverleih = document.getElementById("zeltverleih");
  const eventManagement = document.getElementById("eventManagement");
  const eventAusstattung = document.getElementById("eventAusstattung");
  const eventMarketing = document.getElementById("eventMarketing");
  const eventTechnik = document.getElementById("eventTechnik");
  const kuenstler = document.getElementById("kuenstler");

  useLayoutEffect(() => {
    element = document.getElementById("agenturCard");
  });
  const handleScrollTo = (
    catergory: "zv" | "ea" | "em" | "emg" | "et" | "k"
  ) => {
    let y;
    switch (catergory) {
      case "em":
        y = eventMarketing!.getBoundingClientRect().top + window.pageYOffset;
        break;
      case "ea":
        y = eventAusstattung!.getBoundingClientRect().top + window.pageYOffset;
        break;
      case "emg":
        y = eventManagement!.getBoundingClientRect().top + window.pageYOffset;
        break;
      case "zv":
        y = zeltverleih!.getBoundingClientRect().top + window.pageYOffset;
        break;
      case "et":
        y = eventTechnik!.getBoundingClientRect().top + window.pageYOffset;
        break;
      case "k":
        y = kuenstler!.getBoundingClientRect().top + window.pageYOffset;
        break;
      default:
        y = 0;
    }
    y -= 175;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSecondNavbarFixed(offset >= element!.getBoundingClientRect().height);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [element]);

  return (
    <div className={"background"}>
      {singleNavbar && (
        <AgenturNavbar
          imageUrl={logo}
          selectedTab={"agentur"}
          extended={isNavExpanded}
          onExtendedChange={(val) => setIsNavExpanded(val)}
        ></AgenturNavbar>
      )}
      {!singleNavbar && <Navbar selectedTab={"contact"} imageUrl={btbImage} />}
      <ScrollToTopButton />

      {!isNavExpanded && (
        <>
          <div className={"card bornToClubAlt agenturCard"} id={"agenturCard"}>
            <div className={"agenturSliderHeader"}>ALLES FÜR IHR EVENT</div>
            <div className={"agenturSliderText"}>
              Willkommen bei der Beach Group Agentur – Ihre Event - Experten vom
              Neusiedler See seit 2018. Kreativ, professionell und vor allem
              leidenschaftlich. Wir machen Events einzigartig und unterstützen
              Sie in jeglicher Hinsicht – für Unternehmen, Privatkunden &
              Vereine. ( Über 30 erfolgreiche Events, jetzt bringen wir unsere
              Expertise für Ihr perfektes Event ein! )
            </div>
            <Carousel
              className={"imageCarousel agenturImageCarousel"}
              autoPlay={true}
              axis={"horizontal"}
              showArrows={true}
              infiniteLoop={true}
              interval={5000}
              showStatus={false}
              showIndicators={true}
              showThumbs={false}
            >
              {[...Array(5)].map((x, i) => (
                <div key={i}>
                  <img
                    src={"/agenturContent/slider_" + (i + 1) + ".jpg"}
                    className={"agenturSliderImage"}
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <div
            className={
              !isSecondNavbarFixed
                ? "agenturSecondNavbar"
                : "agenturSecondNavbar fixed"
            }
          >
            <div className={"agenturSecondNavbarInner"}>
              <div
                className={"agenturNavBarText"}
                onClick={() => handleScrollTo("zv")}
              >
                Zeltverleih
              </div>
              <div
                className={"agenturNavBarText"}
                onClick={() => handleScrollTo("ea")}
              >
                Event Ausstattung
              </div>
              <div
                className={"agenturNavBarText"}
                onClick={() => handleScrollTo("em")}
              >
                Event Marketing
              </div>
              <div
                className={"agenturNavBarText"}
                onClick={() => handleScrollTo("emg")}
              >
                Event Management
              </div>
              <div
                className={"agenturNavBarText"}
                onClick={() => handleScrollTo("et")}
              >
                Event Technik
              </div>
              <div
                className={"agenturNavBarText"}
                onClick={() => handleScrollTo("k")}
              >
                Künstlervermittlung
              </div>
            </div>
          </div>
          <div
            className={
              !isSecondNavbarFixed
                ? "agenturSecondNavbarGhost none"
                : "agenturSecondNavbarGhost"
            }
          ></div>
          <div className={"agenturCategoryWrapperWrapper"}>
            <div className={"agenturCategoryWrapper"} id={"zeltverleih"}>
              <div className={"agenturCategoryImages"}>
                <Carousel
                  className={"imageCarousel"}
                  autoPlay={false}
                  axis={"horizontal"}
                  showArrows={true}
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={true}
                  showThumbs={false}
                >
                  {[...Array(3)].map((x, i) => (
                    <div key={i}>
                      <img
                        src={
                          "/agenturContent/zeltverleih/zelt_" + (i + 1) + ".jpg"
                        }
                        className={"agenturCategoryImagesImage"}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
              <div className={"agenturCategoryInfo"}>
                <div className={"agenturCategoryInfoHeader"}>Zeltverleih</div>
                <div className={"agenturCategoryInfoText"}>
                  Professionelle Zeltvermietung für Events jeder Art und Größe.
                  Unser umfangreiches Sortiment umfasst hochwertige Zelte in
                  verschiedenen Stilen und Größen, die für Veranstaltungen wie
                  Hochzeiten, Firmenevents, Festivals und mehr geeignet sind.
                  Von eleganten Pagodenzelten bis hin zu geräumigen Festzelten –
                  wir unterstützen Sie bei der Schaffung der perfekten
                  Atmosphäre für Ihr Event.
                </div>
                <div className={"anfrageButtonWrapper"}>
                  <Link
                    className={"werdePartnerButton"}
                    to={"/agentur/contact"}
                  >
                    Anfrage senden
                  </Link>
                </div>
              </div>
            </div>
            <div
              className={"agenturCategoryWrapper switched"}
              id={"eventAusstattung"}
            >
              <div className={"agenturCategoryInfo right"}>
                <div className={"agenturCategoryInfoHeader right"}>
                  Eventausstattung
                  <div className={"agenturCategoryInfoHeaderTwo"}>
                    (Bar & Ausstattung mit Mobiliar und Dekoration)
                  </div>
                </div>
                <div className={"agenturCategoryInfoText"}>
                  Die richtige Eventausstattung verleiht Ihrem Event den
                  perfekten Glanz. Mit einer vielfältigen Auswahl an
                  hochwertigen Möbeln, Dekorationen und technischen Lösungen
                  sorgen wir dafür, dass jede Veranstaltung stilvoll und
                  funktional ausgestattet ist. Egal ob für private Feiern,
                  Firmenevents oder Messen – wir bieten eine breite Palette an
                  Ausstattungsoptionen.
                </div>
                <div className={"anfrageButtonWrapper"}>
                  <Link
                    className={"werdePartnerButton"}
                    to={"/agentur/contact"}
                  >
                    Anfrage senden
                  </Link>
                </div>
              </div>
              <div className={"agenturCategoryImages"}>
                <Carousel
                  className={"imageCarousel"}
                  autoPlay={false}
                  axis={"horizontal"}
                  showArrows={true}
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={true}
                  showThumbs={false}
                >
                  {[...Array(2)].map((x, i) => (
                    <div key={i}>
                      <img
                        src={
                          "/agenturContent/eventausstattung/eventausstattung_" +
                          (i + 1) +
                          ".jpg"
                        }
                        className={"agenturCategoryImagesImage"}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className={"agenturCategoryWrapper"} id={"eventMarketing"}>
              <div className={"agenturCategoryImages"}>
                <Carousel
                  className={"imageCarousel"}
                  autoPlay={false}
                  axis={"horizontal"}
                  showArrows={true}
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={true}
                  showThumbs={false}
                >
                  {[...Array(3)].map((x, i) => (
                    <div key={i}>
                      <img
                        src={
                          "/agenturContent/eventmarketing/eventmarketing_" +
                          (i + 1) +
                          ".jpg"
                        }
                        className={"agenturCategoryImagesImage"}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
              <div className={"agenturCategoryInfo"}>
                <div className={"agenturCategoryInfoHeader"}>
                  Eventmarketing
                  <div className={"agenturCategoryInfoHeaderTwo"}>
                    (inkl. Content - Produktion)
                  </div>
                </div>
                <div className={"agenturCategoryInfoText"}>
                  Wir sorgen für kreative Strategien, um Veranstaltungen in
                  unser gessliche Markenerlebnisse zu verwandeln. Durch die
                  gezielte Integration von Events in Marketingkampagnen schaffen
                  wir Interaktionsmöglichkeiten, steigern die Markenbekanntheit
                  und fördern die Kundenbindung. Unser Ziel ist es, durch Events
                  eine unmittelbare Verbindung zwischen Ihrer Marke und Ihrem
                  Publikum herzustellen. <br /> <br />
                  Gerne schaffen wir auch visuelle Meisterwerke mit unseren Foto
                  - & Videografen. Ob professionelle Eventfotografie,
                  atmosphärische Videodokumentationen oder Social - Media -
                  Content – wir setzen Ihre Geschichte in beeindruckenden Foto -
                  oder Videomaterial um. Lassen Sie uns gemeinsam den visuellen
                  Charakter Ihres Events gestalten und für bleibende
                  Erinnerungen sorgen.
                </div>
                <div className={"anfrageButtonWrapper"}>
                  <Link
                    className={"werdePartnerButton"}
                    to={"/agentur/contact"}
                  >
                    Anfrage senden
                  </Link>
                </div>
              </div>
            </div>
            <div
              className={"agenturCategoryWrapper switched"}
              id={"eventManagement"}
            >
              <div className={"agenturCategoryInfo right"}>
                <div className={"agenturCategoryInfoHeader right"}>
                  Eventmanagement
                  <div className={"agenturCategoryInfoHeaderTwo"}>
                    (Beratung, Planung & Organisation, Umsetzung)
                  </div>
                </div>

                <div className={"agenturCategoryInfoText"}>
                  Wir verwandeln Visionen in unvergessliche Erlebnisse und
                  kümmern uns um jede Facette Ihres Events - von der Konzeption
                  bis zur Durchführung. Wir bieten maßgeschneiderte Lösungen für
                  Firmenveranstaltungen, Kongresse, Produktlaunches und mehr, um
                  sicherzustellen, dass Ihr Event alle Erwartungen übertrifft
                  und lange in Erinnerung bleibt.
                </div>
                <div className={"anfrageButtonWrapper"}>
                  <Link
                    className={"werdePartnerButton"}
                    to={"/agentur/contact"}
                  >
                    Anfrage senden
                  </Link>
                </div>
              </div>
              <div className={"agenturCategoryImages"}>
                <Carousel
                  className={"imageCarousel"}
                  autoPlay={false}
                  axis={"horizontal"}
                  showArrows={true}
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={true}
                  showThumbs={false}
                >
                  {[...Array(2)].map((x, i) => (
                    <div key={i}>
                      <img
                        src={
                          "/agenturContent/eventmgmt/eventmgmt_" +
                          (i + 1) +
                          ".jpg"
                        }
                        className={"agenturCategoryImagesImage"}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className={"agenturCategoryWrapper"} id={"eventTechnik"}>
              <div className={"agenturCategoryImages"}>
                <Carousel
                  className={"imageCarousel"}
                  autoPlay={false}
                  axis={"horizontal"}
                  showArrows={true}
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={true}
                  showThumbs={false}
                >
                  {[...Array(4)].map((x, i) => (
                    <div key={i}>
                      <img
                        src={
                          "/agenturContent/eventtechnik/technik_" +
                          (i + 1) +
                          ".jpg"
                        }
                        className={"agenturCategoryImagesImage"}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
              <div className={"agenturCategoryInfo"}>
                <div className={"agenturCategoryInfoHeader"}>
                  Eventtechnik
                  <div className={"agenturCategoryInfoHeaderTwo"}>
                    (Tontechnik, Lichttechnik, Bühnentechnik & Strom)
                  </div>
                </div>
                <div className={"agenturCategoryInfoText"}>
                  Unsere Eventtechnik bietet neueste Innovationen für
                  beeindruckende Veranstaltungen. Von professioneller Ton - und
                  Beleuchtungstechnik bis hin zu modernster Visualisierung und
                  Bühnentechnik – wir stellen sicher, dass Ihr Event technisch
                  perfekt ausgestattet ist. Eine breite Palette an Technologien
                  ermöglicht ein nahtloses Erlebnis und unvergessliche Momente
                  für Ihr Publikum.
                </div>
                <div className={"anfrageButtonWrapper"}>
                  <Link
                    className={"werdePartnerButton"}
                    to={"/agentur/contact"}
                  >
                    Anfrage senden
                  </Link>
                </div>
              </div>
            </div>
            <div className={"agenturCategoryWrapper switched"} id={"kuenstler"}>
              <div className={"agenturCategoryInfo right"}>
                <div className={"agenturCategoryInfoHeader right"}>
                  Künstlervermittlung
                  <div className={"agenturCategoryInfoHeaderTwo"}>
                    (DJ, SängerInnen, etc.)
                  </div>
                </div>
                <div className={"agenturCategoryInfoText"}>
                  Tauchen Sie ein in die Welt der Klänge und Unterhaltung mit
                  unserer Künstlervermittlung. Von energiegeladenen DJs über
                  charismatische Alleinunterhalter bis hin zu speziellen Acts –
                  wir bieten eine breite Auswahl, um Ihr Event musikalisch zu
                  untermalen und für die richtige Atmosphäre zu sorgen - ganze
                  gal, ob Sie eine Tanzfläche füllen möchten oder entspannte
                  Hintergrundmusik wünschen.
                </div>
                <div className={"anfrageButtonWrapper"}>
                  <Link
                    className={"werdePartnerButton"}
                    to={"/agentur/contact"}
                  >
                    Anfrage senden
                  </Link>
                </div>
              </div>
              <div className={"agenturCategoryImages"}>
                <Carousel
                  className={"imageCarousel"}
                  autoPlay={false}
                  axis={"horizontal"}
                  showArrows={true}
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={true}
                  showThumbs={false}
                >
                  {[...Array(2)].map((x, i) => (
                    <div key={i}>
                      <img
                        src={
                          "/agenturContent/kuenstler/kuestlervermittlung_" +
                          (i + 1) +
                          ".jpg"
                        }
                        className={"agenturCategoryImagesImage"}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer footerTab={"none"} />
    </div>
  );
}
