import "../styles/Footer.css";
import { Link } from "react-router-dom";
import LootyBanner from "../assets/random/Looty_banner_black.png";

export interface FooterProps {
  footerTab: "impressum" | "datenschutz" | "none";
}

export default function Footer({ footerTab }: FooterProps) {
  return (
    <div className={"footer"}>
      <div className={"footerContent footerContentSplitter"}>
        <Link
          className={
            "link" + " " + (footerTab === "impressum" ? "selected" : "")
          }
          to={"/impressum"}
        >
          Impressum
        </Link>
        <Link
          className={
            "link" + " " + (footerTab === "datenschutz" ? "selected" : "")
          }
          to={"/datenschutz"}
        >
          Datenschutz
        </Link>
      </div>
      <div className={"footerContent"}>
        <span className={"copyright"}>
          Copyright{" "}
          <Link to={"/dashboard"} className={"sneakyButton"}>
            <span>&#169;</span>
          </Link>{" "}
          2024 <br />
          All rights reserved
        </span>
      </div>
      <div className={"footerContent flex"}>
        <p>Made by</p>
        <a
          href={"https://www.instagram.com/looty.official/"}
          className={"imageHref"}
        >
          <img className={"lootyBanner"} src={LootyBanner} alt={"Looty"} />
        </a>
      </div>
    </div>
  );
}
