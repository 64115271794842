import Navbar, { NavbarProps } from "./Navbar";
import "../styles/BornToBeach.css";
import brauunion from "../assets/logos/brauunion.png";
import redbull from "../../public/partner_logos/redBull.png";
import skruf from "../../public/partner_logos/skruf.png";
import btbImage from "../assets/logos/btb.png";
import Footer from "./Footer";
import { EventData } from "./Routes";
import { EventType } from "./EventFormItem";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import AboutImage from "../assets/random/placeholder1.jpg";
import ImageDisplay from "./ImageDisplay";
import { Link } from "react-router-dom";
import { Simulate } from "react-dom/test-utils";
import load = Simulate.load;

export interface EventDisplayProps {
  events?: EventData[];
  type: EventType;
  size: "full" | "half";
  eventTime: "past" | "future";
  partnersOfEvent: Map<string, string[]>;
  solo?: boolean;
  loadingFinished?: boolean;
  minimizeIfNone?: boolean;
  imageIfNone?: string;
}

export default function EventDisplay({
  events,
  type,
  size,
  eventTime,
  partnersOfEvent,
  solo = false,
  loadingFinished,
  minimizeIfNone = false,
  imageIfNone,
}: EventDisplayProps) {
  const [hoverEvent, setHoverEvent] = useState<string | undefined>(undefined);
  const [eventDetails, setEventDetails] = useState<EventData | undefined>(
    undefined
  );
  const eventsToDisplay = useMemo(() => {
    let eventsToDisplay: EventData[] = [];
    if (events) {
      for (let e of events) {
        if (e.type === type && e.shouldDisplay) {
          if (
            eventTime === "past" &&
            DateTime.fromISO(e.date) < DateTime.now()
          ) {
            eventsToDisplay.push(e);
          } else if (
            eventTime === "future" &&
            DateTime.fromISO(e.date) > DateTime.now()
          ) {
            eventsToDisplay.push(e);
          }
        }
      }
    }
    if (eventsToDisplay) {
      eventsToDisplay.sort((a, b) => {
        const dateA = DateTime.fromISO(a.date);
        const dateB = DateTime.fromISO(b.date);
        if (eventTime === "past") {
          if (dateA > dateB) {
            return -1;
          }
          if (dateA < dateB) {
            return 1;
          }
        } else {
          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
        }
        return 0;
      });
    }
    return eventsToDisplay;
  }, [eventTime, events, type]);

  function getPartnersOfEvent(eventId: string) {
    return partnersOfEvent.get(eventId) || [];
  }

  useEffect(() => {
    const onKey = (ev: KeyboardEvent) => {
      if (ev.key === "Escape" || ev.key === "Esc") {
        setEventDetails(undefined);
        setHoverEvent(undefined);
        ev.preventDefault();
        ev.stopPropagation();
      }
    };
    window.addEventListener("keyup", onKey);
    return () => window.removeEventListener("keyup", onKey);
  }, []);

  const isUpcoming =
    (eventDetails && DateTime.fromISO(eventDetails.date) > DateTime.now()) ||
    false;

  const [fullscreenImage, setFullscreenImage] = useState<string | undefined>(
    undefined
  );

  return (
    <>
      {eventDetails && (
        <div className={"eventDetailsWrapper " + solo && solo ? " solo" : ""}>
          <div className={"eventDetailsPanel"}>
            <button
              className={"exitButton"}
              onClick={() => {
                if (fullscreenImage) {
                  setFullscreenImage(undefined);
                } else {
                  setEventDetails(undefined);
                  setHoverEvent(undefined);
                }
              }}
            >
              &#10006;
            </button>
            {!fullscreenImage && (
              <>
                <div className={"eventRowOne"}>
                  <div className={"eventHeader"}>{eventDetails.name}</div>
                  <div className={"eventDate"}>
                    {DateTime.fromISO(eventDetails.date)
                      .toJSDate()
                      .toLocaleDateString()}
                  </div>
                </div>
                <div className={"eventRowTwo"}>
                  <>
                    <div className={"eventText"}>
                      {isUpcoming && (
                        <a
                          href={"" + eventDetails.ticketLink}
                          target={"_blank"}
                        >
                          Tickets <br />
                        </a>
                      )}
                      <div />
                      <div
                        dangerouslySetInnerHTML={{ __html: eventDetails.text }}
                      />
                    </div>
                    {isUpcoming && (
                      <div className={"eventBannerWrapper"}>
                        <img
                          src={`data:image/jpeg;base64,${eventDetails.bannerImage}`}
                          className={
                            "eventImage" +
                            " " +
                            (eventsToDisplay?.length === 1 ? "solo" : "")
                          }
                          alt={eventDetails.name}
                        ></img>
                      </div>
                    )}
                  </>
                </div>
                {partnersOfEvent.size > 0 && (
                  <div
                    className={
                      "partnerRow" +
                      " " +
                      (DateTime.fromISO(eventDetails.date) > DateTime.now()
                        ? "end"
                        : "")
                    }
                  >
                    {getPartnersOfEvent(eventDetails.id).map((partner) => (
                      <div key={partner}>
                        <img
                          className={"partnerLogo"}
                          src={"/partner_logos/" + partner + ".png"}
                          alt={partner}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            {!isUpcoming && (
              <div>
                <ImageDisplay
                  onFullscreenImage={setFullscreenImage}
                  eventDetails={eventDetails}
                  fullscreenImage={fullscreenImage}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className={`eventWrapper ${
          loadingFinished && eventsToDisplay.length === 0 && minimizeIfNone
            ? "minimized"
            : ""
        }`}
        data-size={size}
      >
        <div
          className={"exitHandler"}
          onMouseLeave={() => setHoverEvent(undefined)}
        >
          {eventsToDisplay &&
            eventsToDisplay.map((e) => (
              <Link
                to={"/events/" + e.id}
                key={e.id}
                className={
                  "eventInstance" +
                  " " +
                  (hoverEvent === e.id ? "selectedEvent" : "")
                }
                onMouseEnter={() => setHoverEvent(e.id)}
              >
                <img
                  src={`data:image/jpeg;base64,${e.bannerImage}`}
                  className={
                    "eventImage" +
                    " " +
                    (eventsToDisplay?.length === 1 ? "solo" : "")
                  }
                  alt={e.name}
                ></img>
              </Link>
            ))}
          {eventsToDisplay.length === 0 && (
            <>
              {!loadingFinished && (
                <h1 className={"noEventInfo"}>Events werden geladen...</h1>
              )}
              {loadingFinished && !imageIfNone && (
                <h1 className={"noEventInfo"}>
                  Vorübergehend keine Events vorhanden
                </h1>
              )}
              {loadingFinished && imageIfNone && (
                <img className="noEventsImage" src={imageIfNone} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
