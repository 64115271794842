import Navbar from "./Navbar";
import "../styles/BornToBeach.css";
import brauunion from "../assets/logos/brauunion.png";
import redbull from "../../public/partner_logos/redBull.png";
import skruf from "../../public/partner_logos/skruf.png";
import btbImage from "../assets/logos/btb.png";
import AboutImage from "../assets/random/placeholder1.jpg";
import { EventData, WEBSITE_URL } from "./Routes";
import { BornToBeachProps } from "./BornToBeach";
import SingleNavbar from "./SingleNavbar";
import React, { useState } from "react";
import Footer from "./Footer";
import ScrollToTopButton from "./ScrollToTopButton";
import axios from "axios";

export interface ContactProps {
  singleNavbar: boolean;
  logo: string;
}

export default function Contact({ singleNavbar, logo }: ContactProps) {
  //            <a href={"tel:+436643024986"}>+43 664 3024986</a>
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      let a = await axios.post(WEBSITE_URL + "send-email", formData);
      alert("Email sent successfully!");
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Error sending email");
    }
  };

  return (
    <div className={"background"}>
      {singleNavbar && (
        <SingleNavbar
          imageUrl={logo}
          selectedTab={"contact"}
          extended={isNavExpanded}
          onExtendedChange={(val) => setIsNavExpanded(val)}
        ></SingleNavbar>
      )}
      {!singleNavbar && <Navbar selectedTab={"contact"} imageUrl={btbImage} />}
      <ScrollToTopButton />

      {!isNavExpanded && (
        <div className={"contactWrapper"}>
          <div className={"contactCard beachClubContact"}>
            <div className={"contactHeader"}>
              Tisch&shy;reser&shy;vier&shy;ungen
              <div className={"contactHeaderSubInfo"}>
                Oktoberfest | Beach Club | VIP bei Event
              </div>
            </div>
            <div className={"eventFormWrapper"}>
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <div className={"eventFormRow splitRow"}>
                  <div className={"eventFormCol"}>
                    <label htmlFor="name">Name:</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className={"eventFormCol"}>
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className={"eventFormRow"}>
                  <label htmlFor="message">Message:</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </div>
                <button type="submit">Anfrage senden</button>
              </form>
            </div>
          </div>
          <img
            src={"/clubContent/Tischangebot_oktoberfst-24_website.jpg"}
            style={{ width: "80%", maxHeight: "500px", objectFit: "contain" }}
          />

          <div className={"contactCard beachClubContact"}>
            <div className={"contactHeader"}>Kontakt&shy;daten</div>
            <div className={"contactInfo"}>
              <a href="mailto:gastro@borntobeach.at">gastro@borntobeach.at</a>
              <a
                target={"_blank"}
                href="https://www.google.at/maps/place/BORN+TO+BEACH+CLUB/@47.86615,16.8350274,16.9z/data=!4m13!1m7!3m6!1s0x476c429a6963575f:0x3cc40f1b5be7cdfa!2sSeegel%C3%A4nde+1,+7100+Neusiedl+am+See!3b1!8m2!3d47.9312599!4d16.8335675!3m4!1s0x476c6b88c9a8a99d:0xde4f93d187be58a0!8m2!3d47.8645927!4d16.8345519"
              >
                Nordstrand 1, 7141 Podersdorf am See
              </a>
            </div>
          </div>
        </div>
      )}
      <Footer footerTab={"none"} />
    </div>
  );
}
