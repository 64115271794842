import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import Root from "./layout/Root";
import ErrorPage from "./layout/ErrorPage";
import BornToBeach from "./layout/BornToBeach";
import BornToClub from "./layout/BornToClub";
import Events from "./layout/Events";
import About from "./layout/About";
import Contact from "./layout/Contact";
import Impressum from "./layout/Impressum";
import Datenschutz from "./layout/Datenschutz";
import { AuthProvider, RequireAuth } from "react-auth-kit";
import Login from "./layout/Login";
import App from "./App";
import ScrollToTop from "./layout/ScrollToTop";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
