import Navbar from "./Navbar";
import "../styles/BornToBeach.css";
import brauunion from "../assets/logos/brauunion.png";
import redbull from "../../public/partner_logos/redBull.png";
import skruf from "../../public/partner_logos/skruf.png";
import btbImage from "../assets/logos/btb.png";
import AboutImage from "../assets/random/placeholder1.jpg";
import { EventData } from "./Routes";
import { BornToBeachProps } from "./BornToBeach";
import SingleNavbar from "./SingleNavbar";
import React, { useMemo, useState } from "react";
import Footer from "./Footer";
import ScrollToTopButton from "./ScrollToTopButton";
import { useParams } from "react-router-dom";
import Event from "./Event";
import { Simulate } from "react-dom/test-utils";
import load = Simulate.load;

export interface EventWrapperProps {
  events: EventData[];
  bteLogo: string;
  btcLogo: string;
  btbLogo: string;
}

export default function EventWrapper({
  events,
  btcLogo,
  bteLogo,
  btbLogo,
}: EventWrapperProps) {
  const { id } = useParams();
  const [loadingFinished, setLoadingFinished] = useState(false);

  const eventData = useMemo(() => {
    console.log(events);
    let event: EventData | undefined = undefined;
    for (let event1 of events) {
      if (event1.id === id) {
        event = event1;
        break;
      }
    }
    if (events.length > 0) {
      setLoadingFinished(true);
    }
    return event;
  }, [id, events]);

  return (
    <Event
      loadingFinished={loadingFinished}
      event={eventData}
      logo={
        eventData?.type === "event"
          ? bteLogo
          : eventData?.type === "beach"
          ? btcLogo
          : btbLogo
      }
    />
  );
}
