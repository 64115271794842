import "../styles/BornToBeach.css";
import { EventData } from "./Routes";
import { useMemo, useState } from "react";
import { wait } from "@testing-library/user-event/dist/utils";

export interface ImageDisplayProps {
  eventDetails?: EventData;
  onFullscreenImage?: (src: string) => void;
  fullscreenImage?: string;
  imagesSource?: string;
}

export default function ImageDisplay({
  eventDetails,
  onFullscreenImage,
  fullscreenImage,
  imagesSource,
}: ImageDisplayProps) {
  const a = ["antonio", "fabio", "placeholder1", "placeholder2", "sabi"];
  const landingImages = [
    "012",
    "018",
    "020",
    "028",
    "036",
    "044",
    "050",
    "053",
    "061",
    "063",
    "064",
    "076",
    "086",
    "092",
    "096",
    "113",
  ];
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [selectedImageIndexToBe, setSelectedImageIndexToBe] =
    useState<number>(0);

  const displaysPerSide = 2;
  const [animating, setAnimating] = useState<boolean>(false);

  const [bigTransitioning, setBigTransitioning] = useState<
    "left" | "right" | undefined
  >(undefined);

  function getImageSource(
    index: number,
    eventDetails?: EventData,
    imagesSource?: string
  ) {
    if (imagesSource != undefined) {
      return imagesSource + landingImages[index] + ".jpg";
    }
    if (eventDetails) {
      let val =
        "/eventImages/" +
        eventDetails.id +
        "/" +
        eventDetails.id +
        "_" +
        a[index] +
        ".png";
      return val;
    }
    return "";
  }

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const imageAmount =
    imagesSource == undefined ? a.length - 1 : landingImages.length - 1;
  const displayLeft = selectedImageIndex > 0;
  const displayRight = selectedImageIndex < imageAmount - 1;

  async function changeSelectedImageIndex(change: number) {
    let newVal = selectedImageIndex + change;
    if (newVal >= 0 && newVal < imageAmount) {
      if (change > 0) {
        setBigTransitioning("right");
      } else if (change < 0) {
        setBigTransitioning("left");
      }
      setSelectedImageIndexToBe(newVal);
    }
  }

  const displayImages = useMemo(() => {
    let images: [string, number][] = [];
    let counter = 0;
    for (let i = -displaysPerSide; i <= displaysPerSide; i++) {
      let newVal = selectedImageIndex + i;
      if (newVal >= 0 && newVal < imageAmount) {
        if (imagesSource == undefined) {
          images[counter] = [a[newVal], newVal];
        } else {
          images[counter] = [landingImages[newVal], newVal];
        }
      } else {
        console.log("no image source: " + newVal);
        images[counter] = ["noImageSource", newVal];
      }
      counter++;
    }
    return images;
  }, [displaysPerSide, selectedImageIndex]);

  return (
    <>
      {eventDetails ||
        (imagesSource && (
          <div>
            <div
              className={
                "eventImageDisplaySelectedImageControlWrapper" +
                " " +
                (fullscreenImage ? "marginless" : "")
              }
            >
              <button
                className={"eventImageDisplayControlButton"}
                disabled={!displayLeft}
                onClick={() => changeSelectedImageIndex(-1)}
              >
                {"<"}
              </button>
              <div
                className={
                  "eventImageDisplaySelectedImageWrapper " +
                  (fullscreenImage ? "eventImageDisplayFullscreen" : "")
                }
              >
                <img
                  className={
                    "eventImageDisplaySelectedImage invisible left" +
                    " " +
                    (fullscreenImage ? "fullscreen" : "") +
                    " " +
                    (bigTransitioning === "left"
                      ? "bigTransitioningLeftExtra"
                      : "")
                  }
                  src={getImageSource(
                    selectedImageIndex - 1,
                    eventDetails,
                    imagesSource
                  )}
                  alt={"eventBanner"}
                />
                <img
                  onClick={() => {
                    onFullscreenImage &&
                      onFullscreenImage(
                        getImageSource(
                          selectedImageIndex,
                          eventDetails,
                          imagesSource
                        )
                      );
                  }}
                  className={
                    "eventImageDisplaySelectedImage" +
                    " " +
                    (fullscreenImage ? "fullscreen" : "") +
                    " " +
                    (bigTransitioning === "left"
                      ? "bigTransitioningLeft"
                      : bigTransitioning === "right"
                      ? "bigTransitioningRight"
                      : "")
                  }
                  src={getImageSource(
                    selectedImageIndex,
                    eventDetails,
                    imagesSource
                  )}
                  alt={"eventBanner"}
                  onAnimationEnd={() => {
                    setBigTransitioning(undefined);
                    setSelectedImageIndex(selectedImageIndexToBe);
                  }}
                />
                <img
                  className={
                    "eventImageDisplaySelectedImage invisible right" +
                    " " +
                    (fullscreenImage ? "fullscreen" : "") +
                    " " +
                    (bigTransitioning === "right"
                      ? "bigTransitioningRightExtra"
                      : "")
                  }
                  src={getImageSource(
                    selectedImageIndex + 1,
                    eventDetails,
                    imagesSource
                  )}
                  alt={"eventBanner"}
                />
              </div>
              <button
                className={"eventImageDisplayControlButton"}
                disabled={!displayRight}
                onClick={() => changeSelectedImageIndex(1)}
              >
                {">"}
              </button>
            </div>
            {!fullscreenImage && (
              <div className={"eventImageDisplayRowWrapper"}>
                {displayImages.map(([name, ind], index) => (
                  <div className={"eventImageDisplayWrapper"}>
                    {name != "noImageSource" && (
                      <img
                        style={{
                          filter:
                            "brightness(" +
                            (100 - Math.abs(displaysPerSide - index) * 30) +
                            "%)",
                        }}
                        className={"eventImageDisplay"}
                        src={getImageSource(ind, eventDetails, imagesSource)}
                        alt={"eventBanner"}
                        onClick={() => {
                          changeSelectedImageIndex(ind - selectedImageIndex);
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </>
  );
}
