import Navbar from "./Navbar";
import "../styles/BornToBeach.css";
import brauunion from "../assets/logos/brauunion.png";
import redbull from "../../public/partner_logos/redBull.png";
import skruf from "../../public/partner_logos/skruf.png";
import btbImage from "../assets/logos/btb.png";
import AboutImage from "../assets/random/placeholder1.jpg";
import { EventData, WEBSITE_URL } from "./Routes";
import { BornToBeachProps } from "./BornToBeach";
import SingleNavbar from "./SingleNavbar";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Footer from "./Footer";
import ScrollToTopButton from "./ScrollToTopButton";
import { DateTime } from "luxon";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import { Link } from "react-router-dom";
import { Simulate } from "react-dom/test-utils";
import load = Simulate.load;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export interface EventProps {
  logo: string;
  event?: EventData;
  loadingFinished: boolean;
}

export default function Event({ logo, event, loadingFinished }: EventProps) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [eventFotos, setEventFotos] = useState<Map<number, string[]>>(
    new Map<number, string[]>()
  );
  const [eventSideImg, setEventSideImg] = useState<string | undefined>(
    undefined
  );
  const [numLoad, setNumLoad] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>();
  const hasLoadedRef = useRef(false);
  const imagesPerLoad = 21;
  const numOfLoadsNeeded =
    Math.ceil((event?.numImages || 0) / imagesPerLoad) + 1;

  const isUpcoming =
    (event && DateTime.fromISO(event.date) > DateTime.now()) || false;

  const loadMoreImages = useCallback(() => {
    if (event) {
      setIsLoading(true);
      axios
        .get(WEBSITE_URL + "eventPhotos/" + event?.id + "/" + numLoad)
        .then((response) => {
          let m = eventFotos;
          m.set(numLoad, response.data);
          setEventFotos(m);
          setNumLoad(numLoad + 1);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [event, eventFotos, numLoad]);

  const displayImages = useMemo(() => {
    let imageArray: Array<string> = [];
    for (let i = 0; i < numLoad; i++) {
      eventFotos.get(i)?.forEach((val) => imageArray.push(val));
    }
    return imageArray;
  }, [eventFotos, numLoad]);

  useEffect(() => {
    if (!hasLoadedRef.current && event) {
      hasLoadedRef.current = true;
      loadMoreImages();
    }
  }, [event, loadMoreImages]);

  useEffect(() => {
    if (event) {
      axios
        .get(WEBSITE_URL + "eventSideImg/" + event?.id)
        .then((response) => {
          setEventSideImg(response.data.eventSideImg);
        })
        .catch((err) => console.log(err));
    }
  }, [event, event?.id]);
  return (
    <div className={"background"}>
      <SingleNavbar
        imageUrl={logo}
        selectedTab={"eventName"}
        eventName={event?.name}
        extended={isNavExpanded}
        onExtendedChange={(val) => setIsNavExpanded(val)}
      ></SingleNavbar>
      <ScrollToTopButton />
      {!isNavExpanded && (
        <>
          {event && (
            <div className={"card bornToClubMain columnFlex"}>
              <div className={"eventDisplayWrapper"}>
                <div className={"eventSplit"}>
                  <div className={"eventRowLeft"}>
                    <div className={"cardHeader eventHeader"}>
                      <div className={"eventHeaderWrapper"}> {event.name}</div>
                    </div>
                    <div className={"eventDateOptional"}>
                      {DateTime.fromISO(event.date)
                        .toJSDate()
                        .toLocaleDateString()}
                    </div>
                    {isUpcoming && (
                      <a
                        href={"" + event.ticketLink}
                        target={"_blank"}
                        className={"eventTicketLink"}
                      >
                        HIER GEHT'S ZU DEN TICKETS
                      </a>
                    )}
                    {event.id === "oktoberfest-2024" && (
                      <div style={{ marginTop: "0.5rem" }}>
                        <Link to={"/contact"} className={"eventTicketLink"}>
                          HIER TISCH RESERVIEREN
                        </Link>
                      </div>
                    )}
                    <div
                      className={"eventTextWrapper"}
                      dangerouslySetInnerHTML={{ __html: event.text }}
                    />
                    <div className={"infoImageWrapperOptional"}>
                      <img
                        className={"infoImage"}
                        src={`data:image/jpeg;base64,${eventSideImg}`}
                      ></img>
                      {isUpcoming && (
                        <div className={"contactButtonWrapper"}>
                          <Link
                            className={"werdePartnerButton"}
                            to={"/contact"}
                          >
                            VIP-Reservierung
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={"eventRowRight"}>
                    <div className={"cardHeader"}>
                      <div className={"eventHeaderWrapper right"}>
                        {DateTime.fromISO(event.date)
                          .toJSDate()
                          .toLocaleDateString()}
                      </div>
                      <div className={"infoImageWrapper"}>
                        <img
                          className={"infoImage"}
                          src={`data:image/jpeg;base64,${eventSideImg}`}
                        ></img>
                      </div>
                      {isUpcoming && (
                        <div className={"contactButtonWrapper"}>
                          <Link
                            className={"werdePartnerButton"}
                            to={"/contact"}
                          >
                            {event.id === "oktoberfest-2024"
                              ? "Reservierung"
                              : "VIP-Reservierung"}
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!isUpcoming && (
                  <div className={"eventImages"}>
                    {eventFotos && (
                      <div className={"photoGridWrapper"}>
                        <div className={"photoGrid"}>
                          {displayImages.map((value, index) => (
                            <img
                              className={"eventImagePhoto"}
                              src={`data:image/jpeg;base64,${value}`}
                              key={index}
                              id={"" + index + 1}
                            ></img>
                          ))}
                        </div>
                        {isLoading && (
                          <div style={{ marginTop: "0.5rem" }}>
                            <FontAwesomeIcon
                              icon={faSpinner}
                              spin={true}
                              size={"2xl"}
                            />
                          </div>
                        )}
                        {numLoad < numOfLoadsNeeded && (
                          <button
                            disabled={isLoading}
                            onClick={loadMoreImages}
                            className={"werdePartnerButton"}
                            style={{ marginBottom: "1rem" }}
                          >
                            Weitere Bilder laden
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {!event && (
            <div className={"card bornToClubMain columnFlex"}>
              {!loadingFinished && (
                <div className={"cardHeader"}>Event wird geladen...</div>
              )}
              {loadingFinished && (
                <div className={"cardHeader"}>Event existiert nicht!</div>
              )}
            </div>
          )}
        </>
      )}
      <Footer footerTab={"none"} />
    </div>
  );
}
